import React from 'react';
import PrimeDashboardView from './prime-dashboard.view';

const PrimeDashboard = () : React.ReactElement => {
    return <PrimeDashboardView />;

    
};

export default PrimeDashboard;
