import React, { useEffect, useState, useCallback } from 'react';
import { ATMButton, ATMGrid, ATMHeader, ATMSelect } from 'shared-it-appmod-ui';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { month } from '../../../../constants/common.constant';
import Lang from 'src/libraries/language';
import YearEndRolloverAuditLog from './year-end-rollover-audit-log/year-end-rollover-audit-log.component';
import Confirm from 'src/components/atoms/confirm/confirm.component';

const YearEndRollover: React.FC = () => {
    const { state, actions } = useUpdateSubcontractorDBContext();
    const [year, setYear] = useState('');
    const [startMonth, setStartMonth] = useState('');
    const [endMonth, setEndMonth] = useState('');
    const [showGenerate, setShowGenerate] = useState(false);
    const [buttonAction, setButtonAction] = useState('');
    const [loadEvent, setLoadEvent] = useState(false);
    const [buttonRollBack, setButtonRollBack] = useState(false);
    const [buttonRollOver, setButtonRollOver] = useState(false);
    const [openAuditLogs, setOpenAuditLogs] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);

    useEffect(() => {
        fetchReport();
    }, [])
     
    const fetchReport = async () => {
        setLoadEvent(true);
        const data = await actions.reportYearRoleBackAndRoleOverGet();
        setButtonRollBack(data.payload.data[0].isRollBackPossible)
        setButtonRollOver(data.payload.data[0].isRollOverPossible)
        setLoadEvent(false);
    }
    const handleClick = async (key) => {
        setIsConfirm(true);
        setButtonAction(key)
    };

    const handleConfirm = useCallback(async (key) => {
       
        setIsConfirm(false);
        setLoadEvent(true);
        if (key === 'buttonRollBack') {
            const data = await actions.reportYearRoleBackAndRoleOverPost({
                "isRoleBack":true,
                "isRoleOver":false
              });
        }
        if (key === 'buttonRollOver') {
            const data = await actions.reportYearRoleBackAndRoleOverPost({
                "isRoleBack":false,
                "isRoleOver":true
              }); 
        }
        
        fetchReport();
    }, [actions]);
    
    const handleOpenAuditLogs = () => {
        setOpenAuditLogs(true);
    }
    const handleOnDiscardAuditLogs = () => {
        setOpenAuditLogs(false);
    }

    const handleOnClose = useCallback(async () => {
        setIsConfirm(false);
    }, []);

    return (

        <>
            {isConfirm && (
                <Confirm
                    open={isConfirm}
                    size="tiny"
                    content={((buttonAction == 'buttonRollBack') ? 'The portal will now display data of the previous year. The Reports page will display and allow users to download the data of the previous year. The Update Spend page will display data and allow data entry for the previous year. The Spend Summary page would also display data relevant to the previous year. Are you sure, you want to proceed?' : 'The portal will now display data of the current year. The Reports page will display and allow users to download the data of the current year. The Update Spend page will display data and allow data entry for the current year. The Spend Summary page would also display data relevant to the current year. Are you sure, you want to proceed?')}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleOnClose}
                    onConfirm={() => handleConfirm(buttonAction)}
                />
            )}
            <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>

                <ATMGrid columns={1}>
                    <ATMGrid.Row >
                        
                        <ATMGrid.Column style={{ textAlign: 'center',paddingTop: '25px',paddingBottom: '25px' }}>
                            <ATMButton
                                disabled={!buttonRollBack}
                                size="small"
                                style={{ marginRight: '20px' }}
                                primary
                                loading={loadEvent}
                                onClick={() => handleClick('buttonRollBack')}
                            >
                                Enable Year-end Rollback
                            </ATMButton>
                            <ATMButton
                                size="small"
                                disabled={!buttonRollOver}
                                style={{ margiLeft: '20px' }}
                                primary
                                loading={loadEvent}
                                onClick={() => handleClick('buttonRollOver')}
                            >
                                Enable Year-end Rollover
                            </ATMButton>
                            <ATMButton
                                className="ui primary button"
                                onClick={() => handleOpenAuditLogs()}
                                style={{ padding: '5px 10px',float: 'right',position: 'absolute',right: '-10px',top: '0' }}
                            >
                                Audit Logs
                            </ATMButton>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>

            </div>
            {openAuditLogs && (
                <YearEndRolloverAuditLog
                    openAuditLogs={openAuditLogs}
                    handleOnDiscardAuditLogs={handleOnDiscardAuditLogs}
                />
            )}
        </>
    );


}

export default YearEndRollover;