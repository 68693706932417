import React, { useEffect, useState, useRef, useCallback } from 'react';
import InvalidContractsView from './invalid-contracts.view';
import { ATMContainer, IORGDataTableQueryState } from 'shared-it-appmod-ui';

import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import * as _ from 'lodash';

import { getUserID } from 'src/libraries/users.library';
import { useContractorPageContext } from 'src/contexts/contractorpage.context';
import InvalidContractsModalComponent from './invalid-contracts-edit-modal/invalid-contracts-edit-modal.component';
import InvalidContractsAuditLog from './invalid-contracts-audit-log/invalid-contracts-audit-log.component';

const InvalidContracts: React.FC = () => {


    const [editData, setEditData] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
    const tableParamsRef = useRef({ page: 0, limit: 0 });
    const search: string = window?.location?.search || '?page=1&limit=20';
    const dataRef = useRef<any>();
    const [ContractNumber, setContractNumber] = useState();
    const user_id = getUserID();
    const { state, actions } = useContractorPageContext();

    const [openAuditLogs, setOpenAuditLogs] = useState(false);
    const handleOpen = (value) => {
        if (value.isAvailableInPoTable) {
            setEditData(value);
            setOpen(true);
        }
    };

    useEffect(() => {
        setContractNumber(dataRef.current?.verification_number);
        const page = new URLSearchParams(search).get('page');
        const limit = new URLSearchParams(search).get('limit');
        if (page != null && limit != null) {
            setQueryParams({
                page: parseInt(page ?? '1'),
                limit: parseInt(limit ?? '20'),
            });
        }
        fetchData({ page: 1, limit: 20 })
    }, [queryParams]);

    const handleChange = (key, value: any) => {
        const newData = { ...dataRef.current, ...{ [key]: value } };
        dataRef.current = newData;
    };

    const fetchData = useCallback(
        async (params: any) => {
            setLoading(true);
            // state.invalidContractList?.data.length == 0 && (await actions.invalidContractListGET());
            const { page, limit, sort, order } = params;
            tableParamsRef.current = params;
            const param =
                {
                    sort: sort || '',
                    order: order || '',
                    page: page,
                    limit: limit,
                    vendor_id: user_id,
                    contractNumber: dataRef.current?.contractNumber || '',

                } || queryParams;
            const data1 = await actions?.invalidContractListGET(param);
            setLoading(false);
        },
        [actions]
    );

    const handleSearch = useCallback(async () => {
        setLoading(true);
        const param =
            {
                contractNumber: dataRef.current?.contractNumber || '',
                page: 1,
                limit: 20,
            } || queryParams;
        await fetchData(param);
        setLoading(false);
    }, [setLoading]);

    const handleOnDiscard = () => {
        setOpen(false);
    };
    const handleClear = useCallback(async () => {
        dataRef.current = [];

        setLoading(true);
        const param =
            {
                page: 1,
                limit: 20,
            } || queryParams;
        await fetchData(param);
        setLoading(false);
    }, [setLoading]);

    const handleSuccess = useCallback(
        async (msg) => {
            setLoading(true);
            setOpen(false);
            ToastSuccess(msg);
            const param =
                {
                    contractNumber: dataRef.current?.contractNumber || '',
                    page: 1,
                    limit: 20,
                } || queryParams;
            await fetchData(param);
            setLoading(false);
        },
        [setOpen, setLoading]
    );


    const handleOpenAuditLogs = () => {
        setOpenAuditLogs(true);
    }
    const handleOnDiscardAuditLogs = () => {
        setOpenAuditLogs(false);
    }

    return (
        <>
            <InvalidContractsView
                // toastOpen={false}
                // success={false}
                // toastMessage={''}
                data={state?.invalidContractList?.data || []}
                dataRef={dataRef}
                fetchData={fetchData}
                handleOpen={handleOpen}
                handleSearch={handleSearch}
                totalRecord={state?.invalidContractList?.total_rows || 0}
                loading={loading}
                utilityData={state?.invalidContractList?.UtilityData}
                handleChange={handleChange}
                setContractNumber={setContractNumber}
                handleClear={handleClear}
                handleOpenAuditLogs={handleOpenAuditLogs}
            />
            {open && (
                <InvalidContractsModalComponent
                    open={open}
                    data={editData}
                    handleOnDiscard={handleOnDiscard}
                    handleSuccess={handleSuccess}
                />
            )}

            {openAuditLogs && (
                <InvalidContractsAuditLog
                    openAuditLogs={openAuditLogs}
                    handleOnDiscardAuditLogs={handleOnDiscardAuditLogs}
                />
            )}
        </>
    );
};

export default InvalidContracts;
