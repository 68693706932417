import React, { useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react';
import { ATMGrid, ATMMenu } from 'shared-it-appmod-ui';
import UserConfiguration from './User-confiugration/user-configuration-info/user-config-info.component';
import AdHocSpendData from './ad-hoc-spend-data/ad-hoc-spend-data.component';
import styles from './admin-menu.module.scss';
import BlockExternalUsers from './block-external-users/block-external-users.component';
import ManageAnnouncements from './manage-announcements/manage-announcement-info/manage-announcements-info.component';
import ManageSubcontractor from './manage-subcontractors/manage-subcontractors.component';
import ManageNotification from './managenotification/information/manage-notification.component';
import MassMail from './mass-mail/mass-mail.component';
import YearEndRollover from './year-end-rollover/year-end-rollover.component';

import AdHocSCHFileUpload from './ad-hoc-sch-file-upload/ad-hoc-sch-file-upload.component';
import BlockSpendFileGeneration from './block-spend-file-generation/block-spend-file-generation.component';

const options = {
    MassEmail: 'Mass Email',
    ManageSubcontractors: 'Manage Subcontractors',
    AddUpdateUser: 'Add / Update User',
    ManageAnnouncements: 'Manage Announcements',
    AdHocSpendData: 'Ad-hoc Spend File',
    AdHocSCHFileUpload: 'Ad-hoc SCH File',
    EnableDisableSpendReporting: 'Enable/Disable Spend Reporting',
    YearEndRollover: 'Year-end Rollover',
    EnableDisableAutomatedGenerationofSpendFile:'Enable/Disable Automated Generation of Spend File'
};

const AdminMenu: React.FC = () => {
    const item = localStorage.getItem('selectedTab');
    const [adminPage, setAdminPage] = useState(item ? item : 'MassEmail');
    const [refresh] = useState(false);
    useEffect(() => {
        if (item) {
            setAdminPage(item);
            localStorage.setItem('selectedTab', '');
        }
    }, []);

    // for UT Coverage
    if (process.env.REACT_APP_SWITCH_TO_UNIT_TEST_MODE === 'true') {
    }

    // for UT Coverage
    if (process.env.REACT_APP_SWITCH_TO_UNIT_TEST_MODE === 'true') {
    }

    const handleMenuChange = (val) => setAdminPage(val);

    const renderSwitch = (param) => {
        switch (param) {
            case 'MassEmail':
                return <MassMail />;
            case 'ManageSubcontractors':
                return <ManageSubcontractor />;
            case 'AddUpdateUser':
                return <UserConfiguration />;
            case 'ManageNotification':
                return <ManageNotification />;
            case 'ManageAnnouncements':
                return <ManageAnnouncements />;
            case 'AdHocSpendData':
                return <AdHocSpendData />;
            case 'AdHocSCHFileUpload':
                return <AdHocSCHFileUpload />;
            case 'YearEndRollover':
                return <YearEndRollover />;      
            case 'EnableDisableSpendReporting':
                return <BlockExternalUsers />;
            case 'EnableDisableAutomatedGenerationofSpendFile':
                return <BlockSpendFileGeneration/>        
            default:
                return <ManageSubcontractor />;
        }
    };
    return (
        <Container fluid className={styles.mainContainer}>
            <ATMGrid className={styles.gridWrapper}>
                <summary>
                    <ATMGrid.Column
                        className={styles.sideMenu}
                        style={{ marginTop: '1rem', listStyle: 'none' }}
                    >
                        <ATMMenu vertical collapsible>
                            {Object.entries(options).map(([key, val]) => {
                                return (
                                    <ATMMenu.Item
                                        key={key}
                                        name={key}
                                        active={adminPage === key}
                                        onClick={() => handleMenuChange(key)}
                                    >
                                        {val}
                                    </ATMMenu.Item>
                                );
                            })}
                        </ATMMenu>
                    </ATMGrid.Column>
                </summary>
                <ATMGrid.Column
                    fluid
                    className={`${styles.rightPanel}`}
                    style={{
                        marginTop: '1rem',
                        minWidth: 'calc(100% - 250px)',
                        maxWidth: 'calc(100% - 70px)',
                        flexGrow: 100,
                    }}
                >
                    {!refresh && <>{renderSwitch(adminPage)}</>}
                </ATMGrid.Column>
            </ATMGrid>
        </Container>
    );
};

export default AdminMenu;
