import React from 'react';
import { ATMSegment } from 'shared-it-appmod-ui';
import ManageSubcontractorView from './manage-subcontractor.view';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import Lang from 'src/libraries/language';
import UpdateSubcontrctorComponent from './update-subcontractor-vons/update-subcontractor-vons-info/update-subcontractor-vons.component';
import UpdateSubcontractorSapId from './update-subcontractor-sapId/update-subcontractor-sapId.component';
import ViewAllSubs from './view-all-subs/view-all-subs.component'

const createPane = (menuName: string, Component: any) => ({
    menuItem: menuName,
    render: () => (
        <ATMSegment>
            <Component />
        </ATMSegment>
    ),
});

const ManageSubcontractor: React.FC = () => {
    const panes = [
        createPane(
            Lang.TTL_UPDATE_SUBCONTRACTORS_VONS,
            UpdateSubcontrctorComponent
        ),
        createPane(
            Lang.TTL_UPDATE_SUBCONTRACTORS_SAP_IDS,
            UpdateSubcontractorSapId
        ),
        createPane(
            Lang.TTL_VIEW_ALL_SUBS,
            ViewAllSubs
        ),
    ];

    return (
        <ManageSubcontractorView
            tabs={
                <Tabs
                    menu={{
                        fluid: false,
                        vertical: false,
                        pointing: true,
                        compact: true,
                        size: 'small',
                    }}
                    panes={panes}
                    segment="basic"
                />
            }
        />
    );
};

export default ManageSubcontractor;
