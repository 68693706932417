import React, { useCallback, useState } from 'react';
import AdminDashboardView from './admin-dashboard.view';

const AdminDashboard = (): React.ReactElement => {
    const [graph, setGraph] = useState(false);

    const setValue = useCallback(
        (name, value) => {
            if (name === 'graph') setGraph(value);
        },
        [graph]
    );

    return <AdminDashboardView graph={graph} setValue={setValue} />;
};

export default AdminDashboard;
