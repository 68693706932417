export default {
    TTL_TOAST_ERROR: 'Oops! Something went wrong',
    TTL_PAGE_ACCESS_DENIED: 'Access Denied!',
    TTL_TOAST_SUCCESS: 'Congratulations!',
    TTL_TOAST_WARNING: 'Warning',
    TTL_TOAST_INFO: 'For your information',
    TTL_ADMIN: 'Admin',
    TTL_BACK: 'Back',
    TTL_ADD: 'Add',
    TTL_ADMIN_USERS: 'Admin Users',
    TTL_APPLICATION: 'Subcontracting Spend Reporting',
    TTL_CONFIRMATION: 'Confirmation',
    TTL_CONFIRM_DELETE: 'Confirm Delete',
    TTL_CONFIRM_STATUS: 'Confirm Update Status',
    TTL_CONFIRM_SUBMIT: 'Confirm Submit',
    TTL_CURRENT_ADMIN_USERS: 'Current Admin Users',
    TTL_ERROR: 'Something Went Wrong!',
    TTL_REMINDER_EMAIL_CURRENT_TEMPLATE: 'Current Template',
    TTL_UNDER_CONSTRUCTION: 'Under Construction',
    TTL_CONTRACTOR_INFO: 'Prime Contractor',
    TTL_SUB_CONTRACTOR_MANAGE: 'Subcontractors',
    TTL_DASHBOARD: 'Dashboard',
    TTL_CONTRACTOR_MANAGE: 'Contracts',
    TTL_UPDATE_SPEND: 'Update Spend',
    TTL_VIEW_SPEND_SUMMARY: 'Spend Summary',
    TTL_REPORTS: 'Reports',
    TTL_CONTACT_USER: 'Primes and Contact',
    TTL_SIGNIN: 'Sign In',
    TTL_LOGIN: 'Login',
    TTL_SCARCH_SUBCONTRACTORS: 'Search Subcontractors',
    TTL_SCARCH_CONTRACTS:'Search Contracts',
    TTL_SUBCONTRACTOR_CONTACTS_AND_OTHER_DETAILS: 'Contact and Other Details',
    TTL_SPEND_SUMMARY: 'Spend Summary',
    TTL_PRIME_CONTRACTOR: 'Prime Contractor',
    TTL_PRIME_CONTRACTOR_COMPANY_DETAILS: 'Company Details',
    TTL_CONTRACTOR_EDIT_PRIME_INFORMATION: 'Edit Prime Information',
    TTL_CONTRACTOR_EDIT_UPDATE_PRIME_INFORMATION:
        'Edit / Update Prime Information',
    TTL_CONTRACTOR_PRIMARY_CONTACT_DETAILS: 'Primary Contact Details',
    TTL_CONTRACTOR_SECONDARY_CONTACT_DETAILS: 'Secondary Contact Details',
    TTL_CONTRACTOR_OPIONAL_CONTACT_DETAILS: 'Optional Contact Details',
    TTL_CONTRACTOR_ALTERNATE_CONTACT: 'Alternate Contact',
    TTL_CONTRACTOR_ADDITIOANL_CONTACT_DETAILS: 'Additional Contact Details',
    TTL_EDIT_INFORMATION: 'Edit Information',
    TTL_ADD_CONTRACTS: 'Add Contracts',
    TTL_ADD_SUBCONTRACTOR: 'Add Subcontractor',
    TTL_ADD_SELECTED_SUBCONTRACTOR: 'Add Selected Subcontractors',
    TTL_SUBCONTRACTOR_EDIT_UPDATE_SUBCONTRACTOR: 'Edit / Update Subcontractor',
    TTL_ENTER_A_NEW_SUBCONTRACTOR: 'Enter a New Subcontractor',
    TTL_SUBCONTRACTOR_INFORMATION: 'Subcontractor Information',
    TTL_SUBCONTRACTOR_COMPANY_DETAILS: 'Company Details',
    TTL_SUBCONTRACTOR_CONTACTS_DETAILS: 'Contact Details',
    TTL_SUBCONTRACTOR_OWNERS_DETAILS: 'Owner Details',
    TTL_SUBCONTRACTOR_VERIFICATION_DETAILS: 'Verification Details',
    TTL_WELCOME: 'San Diego Gas & Electric and Southern California Gas Company',
    TTL_SANDIEGO: 'San Diego Gas & Electric',
    TTL_SOCAL: 'Southern California Gas Company',
    TTL_AND: 'and',
    TTL_PRIMES_AND_CONTACT: 'Primes and Contacts',
    TTL_CONTACT_PRIMES: 'Primes',
    TTL_CONTACT_PRIME_CONTRACTOR_INFO: 'Prime Contractor Info',
    TTL_CONTACT_VIEW_SUBCONTRACTORS: 'View Subcontractors',
    TTL_CONTACT_VIEW_CONTRACTS: 'View Contracts',
    TTL_CONTACT_VIEW_SPEND_DATA: 'View Spend Data',
    TTL_CONTACT_VIEW_INVALID_CONTRACTS: 'View Invalid Contracts',
    TTL_PRIMES_EDIT_UPDATE: 'Edit / Update Prime',
    TTL_SAPID_EDIT_UPDATE: 'Edit / Update Subcontractor SAP ID',
    TTL_SHOW_INACTIVE_SUBCONTRACTOR: 'Show Inactive Subcontractors',
    TTL_HIDE_INACTIVE_SUBCONTRACTOR: 'Hide Inactive Subcontractors',
    TTL_AVAILABLE_SUBCONTRACTOR: 'View Available Subcontractors',
    TTL_MANAGE_SUBCONTRACTORS: 'Manage Subcontractors',
    TTL_UPDATE_SUBCONTRACTORS_DATABASE: 'Update Subcontractors Database',
    TTL_UPDATE_SUBCONTRACTORS_VONS: 'Update Subcontractor VONs',
    TTL_UPDATE_SUBCONTRACTORS_SAP_IDS: 'Update Subcontractor SAP IDs',
    TTL_VIEW_ALL_SUBS: 'View All Subs',
    TTL_MANAGE_EXTERNAL_DATA: 'Manage External Data',
    TTL_UPDATE_EXTERNAL_DATA: 'Update External Data',
    TTL_UPDATE_SAP_DATA: 'Update SAP Data',
    TTL_UPDATE_SAP_DATA_DETAIL:
        'This file contains current SCH verification data to update supplier records in SAP.',
    TTL_UPDATE_UNIVERSE_DATA: 'Update SCH for BW Universe data',
    TTL_UPDATE_UNIVERSE_DATA_DETAIL:
        'This file contains current SCH supplementary data to update supplier records in BW.',
    TTL_MASS_MAIL: 'Mass Mail',
    TTL_MANAGE_NOTIFICATIONS: 'Manage Notifications',
    TTL_FORGOT_PASSWORD: 'Forgot Password',

    TTL_UPDATE_SUBCONTRACTORS_VONS_SUBCONTRACTOR: 'Subcontractor',

    TTL_UPDATE_SUBCONTRACTORS_VONS_VON: 'VON',
    TTL_UPDATE_SUBCONTRACTORS_VONS_SELECTED_BY_PRIME: 'Selected By Prime',
    TTL_UPDATE_SUBCONTRACTORS_VONS_EDIT: 'Edit',

    TTL_ADMIN_USER_CONFIG_INFO_ADD_NEW_USER: 'Add New User',
    TTL_ADMIN_USER_CONFIG_INFO_FIRST_NAME: 'First Name',
    TTL_ADMIN_USER_CONFIG_INFO_LAST_NAME: 'Last Name',
    TTL_EMAIL_ADDRESS: 'Email Address',
    TTL_ADMIN_USER_CONFIG_INFO_USER_TYPE: 'User Type',
    TTL_ADMIN_USER_CONFIG_INFO_ALIAS: 'Alias',
    TTL_ADMIN_USER_CONFIG_INFO_UTILITY: 'Utility',
    TTL_PRIME_CONTACT_CONTRACT: 'Contract',
    TTL_ACTION: 'Action',

    TTL_PRIME_CONTACT: 'Prime',
    TTL_PRIME_CONTACT_SUPPLIER_DIVERSITY: 'Supplier Diversity',
    TTL_PRIME_CONTACT_CONTACT: 'Contact',
    TTL_PRIME_CONTACT_ADVISOR: 'Advisor Name',
    TTL_PRIME_CONTACT_CONTACT_1: 'Contact 1',
    TTL_PRIME_CONTACT_CONTACT_2: 'Contact 2',
    TTL_PRIME_CONTACT_CONTACT_3: 'Contact 3',
    TTL_PRIME_CONTACT_FAVOURITES: 'Favorites',
    TTL_PRIME_CONTACT_DATE_ADDED: 'Date Added ',
    TTL_PRIME_CONTACT_DATE_EXPI: 'Expiration Date',
    TTL_PRIME_CONTACT_VALID_FOR_SPEND_ENTRY: 'Contract Valid for Spend Entry',
    TTL_PRIME_CONTACT_VALIDY_EXPLANATION: 'Validity Explanation',
    TTL_PRIME_CONTACT_VIEW_ON_UPDATE_SPEND_SCREEN:
        'View on Update Spend Screen?',
    TTL_CHANGE_TO_SPEND_AMOUNT_CONTRACT: 'Contract',
    TTL_CHANGE_TO_SPEND_AMOUNT_CONTRACT_NAME: 'Contact Name',
    TTL_CHANGE_TO_SPEND_AMOUNT_DATE_OF_CHANGE: 'Date of Change',
    TTL_CHANGE_TO_SPEND_AMOUNT_PREVIOUS_AMOUNT: 'Previous Amount',
    TTL_CHANGE_TO_SPEND_AMOUNT_NEW_AMOUNT: 'New Amount',
    TTL_CHANGE_TO_SPEND_AMOUNT_MON_YR: 'Mon/Yr',
    TTL_REPORT_PRIME_INFO_PRIME_INFORMATION: 'Prime Information',
    TTL_REPORT_PRIME_INFO_SAPID: 'SAP ID',
    TTL_REPORT_PRIME_INFO_SUPPLIER_CODE: 'Supplier Code',
    TTL_REPORT_PRIME_INFO_STATUS: 'Status',
    TTL_REPORT_PRIME_INFO_AGREEMENTS_ALLOWED: 'Agreements Allowed',
    TTL_REPORT_PRIME_INFO_CURRENT_SPEND: 'Current Spend',
    TTL_REPORT_PRIME_INFO_SECONDARY_CONTACT: 'Secondary Contact',
    TTL_REPORT_PRIME_INFO_OPTIONAL_CONTACT: 'Optional Contact',
    TTL_REPORT_VERIFICATION_FOLLOW_UP_VERIFICAITON_EXP_DATE:
        'Verification Expiration Date',
    TTL_REPORT_VERIFICATION_FOLLOW_UP_EMAIL: 'Email',
    TTL_REPORT_VERIFICATION_FOLLOW_UP_PHONE_NO: 'Phone No.',
    TTL_REPORT_VERIFICATION_FOLLOW_UP_ADDRESS: 'Address',
    TTL_REPORT_VERIFICATION_FOLLOW_UP_STATE: 'State',
    TTL_REPORT_VERIFICATION_FOLLOW_UP_ZIP: 'Zip',
    TTL_REPORT_VERIFICATION_FOLLOW_UP_ADMIN_SDC_NAME:
        'Supplier Diversity Contact/Contacts',
    TTL_REPORT_VERIFICATION_FOLLOW_UP_ADMIN_VERIFICATION_EXP_STATUS:
        'Verification Expiration Status',
    TTL_REPORT_VERIFICATION_FOLLOW_UP_ADMIN_SUBCONTRACTOR_SPEND_TOTAL:
        'Subcontractor Spend Total',
    TTL_ADMIN_AGGREGATE_EXPORT_HEADER: 'Aggregate Export',
    TTL_ANNOUNCEMENTS: 'Announcements',
    TTL_MANAGE_ANNOUNCEMENTS: 'Manage Announcements',
    TTL_CONTACT_NUMBER: 'Contact Number',
    TTL_CONTACT_EMAIL: 'Contact Email',
    TTL_SUBCONTRACTOR_NAME: 'Subcontractor Name',
    TTL_VERIFICATION_EXP_DATE: 'Verification Exp. Date',
    TTL_VERIFICATION_NUMBER: 'Verification Number',
    TTL_TOTAL_SPEND_AT_RISK: 'Total Spend at Risk',
    TTL_ADMIN_ADD_HOC_SPEND_HEADER: 'Ad-hoc Spend Data',
    TTL_NEW_MINORITY_CATEGORY:'New Minority Category',
    TTL_ASSIGN_SUPPLIER_CODE:'Assign Minority / Supplier Code',
    TTL_MINORITY_CATEGORY_PRIORITIZATION:'Prioritise Minority Category',
    TTL_MINORITY_CATEGORY:' Minority Category',
    TTL_CONTRACT_NO:'Contract No',
    TTL_ASSOCIATED_PRIME:'Associated Prime',
    TTL_MESSAGE:'Message',
    TTL_START_DATE:'Start Date',
    TTL_END_DATE:'End Date',
    TTL_DONE_BY:'Done By',
    TTL_TIME_STAMP:'Timestamp',
    TTL_EMAIL:'EMail',
    TTL_EXCEPTION_STATUS:'Exception Status',
    TTL_ADD_EXCEPTION_TO_CONTRACTS:'Add Exception to Contracts',
    TTL_MASS_EMAIL:'Mass Email',
    TTL_MASS_EMAIL_AUDIT_LOGS:'Mass Email Audit Logs',
    TTL_TRIGGERE_BY:'Triggered By',
    TTL_STATUS:'Status',
    TTL_AUTO_SPEND_FILE_AUDIT_LOGS:'Automated Spend File Generation Audit Logs',
    TTL_AUTOMATED_SPEND_FILE_GENERATION_STATUS:'Automated Spend File Generation Status',
    TTL_AUTOMATED_SPEND_FILE_GENERATION_ENABLED:'Automated Spend File Generation Enabled',
    TTL_AUTOMATED_SPEND_FILE_GENERATION_DISABLED:'Automated Spend File Generation Disabled',
};
