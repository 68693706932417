import { type } from 'os';
import yup from '../libraries/validator.library';

export const updateSubcontractorDB = yup.object({
    data: yup.object({
        id: yup.number(),
        processCode: yup.string(),
        status: yup.string(),
    }),
    status: yup.string(),
    message: yup.string(),
});
export const schFileStatusSchema = yup.object({
    data: yup.object({
        isInProgress: yup.boolean()
    }),
    status: yup.boolean(),
    message: yup.string(),
});


export type IschFileStatusSchema = yup.InferType<
typeof schFileStatusSchema
>;


export const schFileProcessAndUpdateSubcontractorsSchema = yup.object({
    data: yup.object({
        id: yup.number(),
        processCode: yup.string(),
        status: yup.string(),
    }),
    status: yup.boolean(),
    message: yup.string(),
});


export type IschFileProcessAndUpdateSubcontractorsSchema = yup.InferType<
typeof schFileProcessAndUpdateSubcontractorsSchema
>;



export const SapIdAuditLogSchema = yup
    .object({
        subcontractor_name: yup.string().nullable(),
        verificationNumber: yup.string().nullable(),
        sap_Id: yup.number(),
        old_sap_Id: yup.number(),
        hidden: yup.boolean(),
        actionID: yup.number(),
        loggedAt: yup.string().nullable(),
        loggedByUser: yup.string().nullable(),
        actionName: yup.string().nullable(),
        Reason: yup.string().nullable(),
        prime_name: yup.string().nullable(),
    })
    .defined();

export const SubsWithoutSapIdAuditLogSchema = yup
    .object({
        data: yup.array().of(SapIdAuditLogSchema).defined(),
        message: yup.string().defined(),
        total_pages: yup.number(),
        total_rows: yup.number(),
        status: yup.boolean(),
    })
    .defined();

export const VonAuditLogSchema = yup
    .object({
        actionID: yup.number(),
        actionName: yup.string().nullable(),
        loggedAt: yup.string().nullable(),
        loggedByUser: yup.string().nullable(),
        subcontractor_name: yup.string().nullable(),
        verificationNumber: yup.string().nullable(),
        old_verificationNumber: yup.string().nullable(),
    })
    .defined();

export const SubsWithoutVonAuditLogSchema = yup
    .object({
        data: yup.array().of(VonAuditLogSchema).defined(),
        message: yup.string().defined(),
        total_pages: yup.number(),
        total_rows: yup.number(),
        status: yup.boolean(),
    })
    .defined();

export const viewAllSubLog = yup
    .object({
        verificationNumber: yup.string(),
        subcontractor_name: yup.string().nullable(),
        loggedByUser: yup.string().nullable(),
        loggedAt: yup.string().nullable(),
        hidden: yup.boolean(),
        actionName: yup.string().nullable(),
        actionID: yup.number(),
        Reason: yup.string().nullable(),
        reasonForDeletion: yup.string().nullable(),
    })
    .defined();

export const ViewAllSubAuditLogSchema = yup
    .object({
        data: yup.array().of(viewAllSubLog).defined(),
        message: yup.string().defined(),
        total_pages: yup.number(),
        total_rows: yup.number(),
        status: yup.boolean(),
    })
    .defined();

export const VonsListSchema = yup
    .object({
        name: yup.string().nullable(),
        primename: yup.string().nullable(),
        subcontractor_id: yup.number().nullable(),
        verificationNumber: yup.string().nullable(),
        verificationExpirationDate: yup.string().nullable(),
        VerificationStatus: yup
            .object({ label: yup.string().defined() })
            .defined(),
    })
    .defined();

export const UpdateSubcontractorVonsListPayload = yup
    .object({
        data: yup.array().of(VonsListSchema).defined(),
        total_pages: yup.number().nullable(),
        total_rows: yup.number().nullable(),
    })
    .defined();

export const UpdateSubcontractorVonPostSchema = yup.object({
    subcontractor_id: yup.string().nullable(),
    verification_number: yup.string().nullable(),
    old_verification_number: yup.string().nullable(),
});

export const updateSubcontractorVonSchema = yup //using for validation
    .object({
        verificationNumber: yup
            .string()
            .max(15, "VON can't be longer than 15 characters")
            .required('VON is required field')
            .matches(/^[a-z0-9]+$/i, 'VON must contain only alphanumeric'),
    })
    .defined();

    export const deleteAllSubSchema = yup //using for validation
    .object({
        reasonForDeletion: yup
            .string()
            .max(512, "Deletion Reason can't be longer than 512 characters")
            .required('Deletion Reason is required field'),
    })
    .defined();

export const updateSubsSapId = yup
    .object({
        subcontractor_name: yup.string().defined(),
        sap_id: yup.string().nullable().defined(),
        subcontractor_id: yup.string().defined(),
        prime_name: yup.string().defined(),
        verification_number: yup.string().defined(),
        expiration_date: yup.string().defined(),
        yearly_spend_amount: yup.string().defined(),
        hidden: yup.boolean(),
        reason: yup.string().defined(),
        reasonId: yup.number(),
        VSID: yup.string().defined(),
    })
    .defined();

export const updateSubcontractorSapId = yup
    .object({
        data: yup.array().of(updateSubsSapId).defined(),
        total_pages: yup.number(),
        total_rows: yup.number(),
        status: yup.boolean(),
    })
    .defined();
export const viewAllSub = yup
    .object({
        VSID: yup.number().defined(),
        company_name: yup.string().defined(),
        phone: yup.string().defined(),
        address: yup.string().defined(),
        verification_number: yup.string().defined(),
        reason: yup.number().defined(),
        reason_name: yup.string().defined(),
        subcontractor_id: yup.number().defined(),
        owner_name: yup.string().defined(),
        sap_id: yup.string().defined(),
        ethinicity: yup.string().defined(),
        city: yup.string().defined(),
        nationality_id: yup.number().defined(),
        hidden: yup.boolean().defined(),
        state: yup.string().defined(),
        gender: yup.number().defined(),
        gender_name: yup.string().defined(),
        contact_email: yup.string().defined(),
        contact_name: yup.string().defined(),
        verification_agency_id: yup.number().defined(),
        verification_agency_name: yup.string().defined(),
        zip: yup.string().defined(),
        supplier_code: yup.string().defined(),
        dba: yup.string().defined(),
        primename: yup.string().defined(),
        verificationExpirationDate: yup.string().defined(),
        VerificationStatus: yup
            .object({ label: yup.string().defined() })
            .defined(),
    })
    .defined();
export const ViewAllSubConstractor = yup
    .object({
        data: yup.array().of(viewAllSub).defined(),
        message: yup.string().defined(),
        total_pages: yup.number(),
        total_rows: yup.number(),
        status: yup.boolean(),
    })
    .defined();
export const UpdateSapIdPostSchema = yup.object({
    data: yup.array().defined(),
});

export const ReasonShape = {
    Reason_Id: yup.number().nullable().defined(),
    Reason: yup.string().nullable().defined(),
};
export const SubcontractorReasonSchema = yup.object(ReasonShape).defined(); // for reason list

export const SubcontractorReasonGetSchema = yup
    .object({
        data: yup.array().of(SubcontractorReasonSchema).defined(),
    })
    .defined();

export const BlockAndUnblockExternalUserSchema = yup
    .object({
        message: yup.string().nullable().defined(),
        status: yup.boolean(),
    })
    .defined();
export const BlockAndUnblockExternalUserGetSchema = yup
    .object({
        data: yup
            .array()
            .of(
                yup
                    .object({
                        isBlockedExternalUser: yup.boolean(),
                    })
                    .defined()
            )
            .defined(),
        message: yup.string().nullable().defined(),
        status: yup.boolean(),
    })
    .defined();

export const BlockAndUnblockExternalUserAuditLogGetSchema = yup
    .object({
        data: yup
            .array()
            .of(
                yup
                    .object({
                        spendReportingStatus: yup.boolean(),
                        // disabledAt: yup.string().nullable().defined(),
                        loggedAt: yup.string().nullable().defined(),
                        firstName: yup.string().nullable().defined(),
                        lastName: yup.string().nullable().defined(),
                        // unBlockedByUser: yup.string().nullable().defined(),
                    })
                    .defined()
            )
            .defined(),
        message: yup.string().nullable().defined(),
        status: yup.boolean(),
        total_rows: yup.number(),
        total_pages: yup.number(),
    })
    .defined();
export const ReportYearRoleBackAndRoleOverPostSchema = yup
    .object({
        data: yup.array().of(yup.mixed()).defined(),
        status: yup.boolean().nullable(),
        message: yup.string(),
    })
    .defined();
export const ReportYearRoleBackAndRoleOverGetSchema = yup
    .object({
        data: yup.array().of(yup.mixed()).defined(),
        status: yup.boolean().nullable(),
        message: yup.string(),
    })
    .defined();
export const ReportingyearsGetSchema = yup
    .object({
        data: yup.array().of(yup.mixed()).defined(),
        status: yup.boolean().nullable(),
        message: yup.string(),
    })
    .defined();
    
export const YearEndRolloverAuditLogGetSchema = yup
.object({
    data: yup
        .array()
        .of(
            yup
                .object({
                    roleBackStatus: yup.boolean(),
                    roleOverStatus: yup.boolean(),
                    createdAt: yup.string().nullable().defined(),
                    doneByUser: yup.string().nullable().defined(),
                    report_year: yup.number(),
                })
                .defined()
        )
        .defined(),
    message: yup.string().nullable().defined(),
    status: yup.boolean(),
    total_rows: yup.number(),
    total_pages: yup.number(),
})
.defined();
export type IYearEndRolloverAuditLogGetSchema = yup.InferType<
typeof YearEndRolloverAuditLogGetSchema
>;

    export type IReportingyearsGetSchema = yup.InferType<
    typeof ReportingyearsGetSchema
>;

export type IReportYearRoleBackAndRoleOverGetSchema = yup.InferType<
typeof ReportYearRoleBackAndRoleOverGetSchema
>;

export type IReportYearRoleBackAndRoleOverPostSchema = yup.InferType<
    typeof ReportYearRoleBackAndRoleOverPostSchema
>;

export type IBlockAndUnblockExternalUserGetSchema = yup.InferType<
    typeof BlockAndUnblockExternalUserGetSchema
>;
export type IBlockAndUnblockExternalUserSchema = yup.InferType<
    typeof BlockAndUnblockExternalUserSchema
>;

export type IBlockAndUnblockExternalUserAuditLogGetSchema = yup.InferType<
    typeof BlockAndUnblockExternalUserAuditLogGetSchema
>;

export type IUpdateSubcontractorVonsListPayload = yup.InferType<
    typeof UpdateSubcontractorVonsListPayload
>;

export type IVonsList = yup.InferType<typeof VonsListSchema>;

export const ViewAllSubcontractorSchemaData = ViewAllSubAuditLogSchema;
export type ISubsWithoutSapIdAuditLogSchema = yup.InferType<
    typeof SubsWithoutSapIdAuditLogSchema
>;
export type ISubsWithoutVonAuditLogSchema = yup.InferType<
    typeof SubsWithoutVonAuditLogSchema
>;
export type IViewAllSubAuditLogSchema = yup.InferType<
    typeof ViewAllSubcontractorSchemaData
>;

export const UpdateSubcontractorDBSchema = updateSubcontractorDB;
export type IUpdateSubcontractorDB = yup.InferType<
    typeof UpdateSubcontractorDBSchema
>;

export type IUpdateSubsSapId = yup.InferType<typeof updateSubsSapId>;
export type IUpdateSubsSapIdTesting = yup.InferType<typeof updateSubsSapId>;

export const UpdateSubcontractorSapIdSchema = updateSubcontractorSapId;
export type IUpdateSubcontractorSapId = yup.InferType<
    typeof UpdateSubcontractorSapIdSchema
>;
export type IUpdateSubcontractorSapIdTesting = yup.InferType<
    typeof UpdateSubcontractorSapIdSchema
>;
export const ViewAllSubcontractorSchema = ViewAllSubConstractor;
export type IUpdateViewAllSubcontractor = yup.InferType<
    typeof ViewAllSubcontractorSchema
>;
export type ISubList = yup.InferType<typeof viewAllSub>;
export type ISubcontractorReasonGet = yup.InferType<
    typeof SubcontractorReasonGetSchema
>;
export type IUpdateSapIdPost = yup.InferType<typeof UpdateSapIdPostSchema>;
