import React, { useCallback, useState } from 'react';
import SubcontractorView from './subcontractor.view';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import Lang from 'src/libraries/language';
import { ATMSegment } from 'shared-it-appmod-ui';
import SubcontractorList from './subcontractor-list/subcontractor-list.component';
import history from 'src/history';

const createPane = (menuName: string, Component: any, value: number) => ({
    menuItem: menuName,
    render: () => (
        <ATMSegment>
            <Component verificationExpirationStatus={value} />
        </ATMSegment>
    ),
});

const Subcontractor: React.FC = () => {
    const [open, setOpen] = useState(false);

    const AddSubContractor = useCallback(() => {
        history.push(`/subcontractors/add`);
    }, [setOpen]);

    const panes = [
        createPane(
            Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_ALL,
            SubcontractorList,
            0
        ),
        createPane(
            Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_NEXT_60_DAYS,
            SubcontractorList,
            1
        ),
        createPane(
            Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_1_180_DAYS_PAST_DUE,
            SubcontractorList,
            2
        ),
        createPane(
            Lang.LBL_SUBCONTRACTOR_LIST_MORE_THAN_180_DAYS_PAST_DUE,
            SubcontractorList,
            3
        ),
        createPane(
            Lang.LBL_SUBCONTRACTOR_LIST_NOT_FOUND_IN_SCH,
            SubcontractorList,
            4
        ),
        createPane(
            Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFIED,
            SubcontractorList,
            5
        ),
    ];
    return (
        <>
            <SubcontractorView
                tabs={
                    <Tabs
                        menu={{
                            fluid: false,
                            vertical: false,
                            pointing: true,
                            compact: true,
                            size: 'small',
                        }}
                        panes={panes}
                        segment="basic" 
                    />
                }
                AddSubContractor={AddSubContractor}
            />
        </>
    );
};

export default Subcontractor;
