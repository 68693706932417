import yup from '../libraries/validator.library';

export const DashboardStatusSchema = yup
    .object({
        count: yup.number().defined(),
        type: yup.string().defined()
    })
    .defined();

export const FetchDashboardStatusSchema = yup
    .object({
        data: yup.array().of(DashboardStatusSchema).defined(),
        status: yup.boolean().defined()
    })
    .defined();


export type IDashboardStatusSchema = yup.InferType<typeof DashboardStatusSchema>;
export type IFetchDashboardStatusSchema = yup.InferType<
    typeof FetchDashboardStatusSchema
>;
