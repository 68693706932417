/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import {
    FetchDashboardStatusSchema
} from '../models/dashboard.model';
import {
    ReportingyearsGetSchema
} from '../models/spend.model';
const client = httpClient();

export const services = {
    getDashboardStatus: async (params) => {
        return client.get(
            `/getDashboardStatus`,
            params,
            FetchDashboardStatusSchema
        );
    },
    reportingyearsGet: async () => {
        return client.get('/reportingYears', {}, ReportingyearsGetSchema);
    },
};

export default services;
