import React from 'react';
import { ATMModal, ATMButton } from 'shared-it-appmod-ui';
import { ConfirmProps } from 'semantic-ui-react';
import Lang from 'src/libraries/language';

type IConfirm = ConfirmProps & {
    loading?: boolean;
    btn_text?: string;
    content?: string | JSX.Element;
    shortContent?: string | JSX.Element;
    open: boolean;
    size?: string;
    onCancel?: () => void;
    onConfirm?: () => void;
    isDelete?: boolean,
    disabled?: boolean
};

const Confirm: React.FC<IConfirm> = ({
    loading = false,
    isDelete = false,
    disabled = false,
    header,
    btn_text,
    content,
    shortContent,
    onConfirm,
    onCancel,
    open,
    size,
}) => (
    <>    
        <ATMModal style={{left: 0, right: 0, top: 0, bottom: 0, height: '178px', margin: 'auto'}} btn_text={btn_text} open={open} size={size} onClose={onCancel} closeOnEscape={false} closeOnDimmerClick={false}>
            {header && <ATMModal.Header>{header}</ATMModal.Header>}
            <ATMModal.Content>{content ?? 'Are you sure?'}</ATMModal.Content>
            {shortContent ? <ATMModal.Content>{shortContent}</ATMModal.Content> : null}
            <ATMModal.Actions>
                <ATMButton secondary onClick={onCancel}>
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    primary
                    style={{ background: isDelete? '#B90000': ''}}
                    onClick={onConfirm}
                    loading={loading}
                    disabled={disabled}
                >
                    {btn_text ? btn_text : Lang.LBL_OKAY}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    </>
);

export default Confirm;
