import React, { useEffect, useState } from 'react';
import style from './update-spend.module.scss';
import {
    ATMButton,
    ATMConfirmationModal,
    ATMContainer,
    ATMGrid,
    ATMHeader,
    ATMLoader,
    ATMSegment,
    ATMToastMessage,
} from 'shared-it-appmod-ui';
import { SpendTable } from './spend-table.view';
import { SpendHead } from './spend-head.view';
import Lang from 'src/libraries/language';
import { useSpendContext } from 'src/contexts/spend.context';
import { isAdmin, isPrime, isContact } from 'src/libraries/users.library';

export const getPONumbers: any = (arr): React.ReactNodeArray => {
    if (!arr) return [];
    return arr.map((i: { contract_number: number }) => i.contract_number);
};

export const isNormalInteger = (str: string): boolean => {
    let str1 = str.trim();
    str1 = str1.replace(/^0+/, '') || '0';
    const n = Math.floor(Number(str1));
    return n !== Infinity && String(n) === str1 && n >= 0;
};

export const tableExist = (data: any, type: string): boolean | null => {
    const unVerified = data?.subcontractors?.filter(
        (a: { is_verified: any }) => !a?.is_verified
    );
    const verified = data?.subcontractors?.filter(
        (a: { is_verified: any }) => a?.is_verified
    );

    if (type === 'unVerified') {
        if (unVerified?.length > 0) {
            return true;
        } else {
            return false;
        }
    } else if (type === 'verified') {
        if (verified?.length > 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return null;
    }
};
interface IUpdateSpendView {
    toastOpen: boolean;
    success: boolean;
    toastMessage: string;
    error: boolean;
    setShowToast: (x: boolean) => void;
    handleReset: () => void;
    handleSave: () => void;
    handleSearch: (data: any) => void;
    handleNoSpendReportModal: () => void;
    handleSubcontractorChange: (subs: string) => void;
    handlePOChange: (po: string) => void;
    handleUtilsChange: (util: string) => void;
    handleTierChange: (tier: string) => void;
    handleSpendAmtChange: (spend: number | string) => void;
    handleMonthChange: (month: number) => void;
    handlePageChange: (page: string) => void;
    handleCellChange: (
        obj: Record<string, { i: any; j: any }>,
        change: Record<string, string>[]
    ) => void;
    changes: Record<string, any>[];
    utChanges: boolean;
    page: number;
    loading: boolean;
    search: boolean;
    handleYearChange: (year: number) => void;
    isJanuary: number;
    isBlockContent: boolean;
    currentApiYear: number;
    isTier2: boolean;
    isTierChange: boolean;
    tier: string;
}

const UpdateSpendView: React.FC<IUpdateSpendView> = ({
    toastOpen,
    success,
    toastMessage,
    error,
    setShowToast,
    handleReset,
    handleSave,
    handleSearch,
    handleNoSpendReportModal,
    handleSubcontractorChange,
    handlePOChange,
    handleUtilsChange,
    handleTierChange,
    handleSpendAmtChange,
    handleMonthChange,
    handlePageChange,
    handleCellChange,
    changes,
    utChanges,
    page,
    loading,
    search,
    isJanuary,
    handleYearChange,
    isBlockContent,
    currentApiYear,
    isTier2,
    isTierChange, tier
}) => {
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    const [floatErrorMessage, setFloatErrorMessage] = useState(false);
    const [maxError, setMaxError] = useState(false);
    const [utility, setUtility] = useState<number>();
    const { state } = useSpendContext();
    const isUserAdmin = isAdmin();
    const isUserPrime = isPrime();
    const isUserContact = isContact();
    const [tierLabel, setTierLabel] = useState<string>("");

    const openSaveModal = () => {
        setSaveModalOpen(true);
    };

    const closeSaveModal = () => {
        setSaveModalOpen(false);
    };

    const handleAction = () => {
        handleSave();
        closeSaveModal();
    };

    const handleFloatErrorMessage = (
        value: boolean | ((prevState: boolean) => boolean)
    ) => {
        setFloatErrorMessage(value);
    };

    const handleMaxError = (
        value: boolean | ((prevState: boolean) => boolean)
    ) => {
        setMaxError(value);
    };

    const findFloatInChanges = (changes1: any[]) => {
        return changes1.some(
            (i: { new_spend_amount: any }) =>
                !isNormalInteger(String(i?.new_spend_amount))
        );
    };

    const findMoreThan8Digits = (changes1: any[]) => {
        if (
            changes1.some(
                (i: { new_spend_amount: any }) =>
                    String(i?.new_spend_amount).length > 8
            )
        ) {
            return true;
        }
        return false;
    };


    
    return (
        <>
            <ATMContainer fluid className={style.wrapper}>
                {floatErrorMessage && (
                    <ATMToastMessage
                        className={style.toastError}
                        onDismiss={() => setFloatErrorMessage(false)}
                        icon="warning circle"
                        timeout={5}
                        position="center"
                        error
                        style={{
                            width: '150%',
                            marginLeft: '-150px',
                            marginTop: 61.47,
                        }}
                        header={`Please enter figures as whole numbers
                            only. Do not use any formatting characters,
                            including dollar signs, decimal points or commas.`}
                    />
                )}
                {maxError && (
                    <ATMToastMessage
                        className={style.toastError}
                        onDismiss={() => setMaxError(false)}
                        icon="warning circle"
                        timeout={5}
                        position="center"
                        error
                        style={{
                            width: '150%',
                            marginLeft: '-150px',
                            marginTop: 61.47,
                        }}
                        header={`Spend amount can’t be longer than 8 digits.`}
                    />
                )}
                {toastOpen && (
                    <ATMToastMessage
                        className={
                            success ? style.toastSuccess : style.toastError
                        }
                        onDismiss={() => setShowToast(false)}
                        icon={success ? 'check circle' : 'warning circle'}
                        timeout={5}
                        error={error}
                        position="center"
                        success={success}
                        header={toastMessage}
                        style={{
                            marginTop: 61.47,
                            width: '70%',
                            marginLeft: '15%',
                        }}
                    />
                )}
                <>
                    <ATMConfirmationModal
                        isActive={saveModalOpen}
                        modalContent={'Please confirm your changes.'}
                        headerContent="Confirmation"
                        cancelButtonContent="Cancel"
                        confirmButtonContent="Confirm"
                        openModal={() => openSaveModal()}
                        closeModal={() => closeSaveModal()}
                        action={() => handleAction()}
                    />
                    {isUserPrime && !isBlockContent && (
                        <ATMContainer fluid>
                            <ATMHeader as="h1" className={style.headerContent}>
                                <span>{Lang.TTL_UPDATE_SPEND}</span>
                                <div>{tierLabel}</div>
                                <div>
                                    <ATMButton
                                        disabled={utChanges}
                                        danger
                                        color="red"
                                        onClick={() => handleNoSpendReportModal()}
                                        style={{ marginRight: '12px' }}
                                    >
                                        {Lang.LBL_NO_SPEND_TO_REPORT}
                                    </ATMButton>
                                    <ATMButton
                                        basic
                                        color="blue"
                                        onClick={() => handleReset()}
                                        disabled={changes.length === 0}
                                        style={{ marginRight: '12px' }}
                                    >
                                        {Lang.LBL_RESET}
                                    </ATMButton>
                                    <ATMButton
                                        disabled={
                                            changes.length === 0 ||
                                            findFloatInChanges(changes) ||
                                            findMoreThan8Digits(changes)
                                        }
                                        primary
                                        onClick={() => openSaveModal()}
                                    >
                                        {Lang.LBL_SAVE}
                                    </ATMButton>
                                </div>
                            </ATMHeader>
                        </ATMContainer>
                    )}
                    {!isBlockContent && (<ATMSegment>
                        <SpendHead
                            tier={tier}
                            setTierLabel={setTierLabel}
                            handleSearch={handleSearch}
                            handleSubcontractorChange={
                                handleSubcontractorChange
                            }
                            handlePOChange={handlePOChange}
                            handleUtilsChange={handleUtilsChange}
                            handleTierChange={handleTierChange}
                            handleSpendAmtChange={handleSpendAmtChange}
                            handleMonthChange={handleMonthChange}
                            handlePageChange={handlePageChange}
                            page={page}
                            setUtility={setUtility}
                            isJanuary={isJanuary}
                            handleYearChange={handleYearChange}
                            currentApiYear={currentApiYear}
                            isTier2={isTier2}
                            isTierChange={isTierChange}
                        />
                        {((isUserAdmin || isUserContact) || (isUserPrime && utility)) &&
                            loading === true && (
                                <ATMGrid.Row
                                    className={style.spendTable}
                                    style={{ height: 100 }}
                                >
                                    <ATMLoader
                                        active
                                        className={style.spendTable}
                                        style={{ marginTop: 50 }}
                                        size="large"
                                    />
                                </ATMGrid.Row>
                            )}

                        { !utility && (
                            <ATMGrid.Row
                                style={{
                                    // border: '1px solid red',
                                    textAlign: 'center',
                                    lineHeight: '65vh',
                                }}
                            >
                                <i
                                    aria-hidden="true"
                                    className="info circle icon"
                                ></i>
                                {Lang.MSG_UPDATED_SPEND_UTILITY_SELECTED}

                            </ATMGrid.Row>
                        )}
                        {loading === false &&
                            state?.list?.subcontractors.length === 0 ? (
                            <ATMGrid.Row
                                className={style.spendTable}
                                style={{ height: 100 }}
                            >
                                <div
                                    style={{
                                        height: 100,
                                        textAlign: 'center',
                                        paddingTop: '30px',
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="info circle icon"
                                    ></i>
                                    {Lang.MSG_NO_RECORDS_TO_DISPLAY}
                                </div>
                            </ATMGrid.Row>
                        ) : (
                            <>
                                {!isTierChange && ((isUserAdmin || isUserContact) || (isUserPrime && utility)) && (
                                    <>
                                        <ATMGrid.Row
                                            className={style.spendTable}
                                        >
                                            {tableExist(
                                                state?.list,
                                                'verified'
                                            ) && (
                                                    <>
                                                        {!loading && (
                                                            <SpendTable
                                                                header={
                                                                    Lang.LBL_SPEND_VERIFIED_SPEND
                                                                }
                                                                headerColor="#2DAD4E"
                                                                handleCellChange={
                                                                    handleCellChange
                                                                }
                                                                setFloatError={
                                                                    handleFloatErrorMessage
                                                                }
                                                                handleMaxError={
                                                                    handleMaxError
                                                                }
                                                                type={
                                                                    'verified'
                                                                }
                                                                changes={
                                                                    changes
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                )}
                                        </ATMGrid.Row>
                                        <ATMGrid.Row>
                                            {tableExist(
                                                state?.list,
                                                'unVerified'
                                            ) && (
                                                    <>
                                                        {!loading && (
                                                            <SpendTable
                                                                header={
                                                                    'Non Verified Spend'
                                                                }
                                                                handleCellChange={
                                                                    handleCellChange
                                                                }
                                                                headerColor="#DB2828"
                                                                unverifiedSpendTable
                                                                setFloatError={
                                                                    handleFloatErrorMessage
                                                                }
                                                                handleMaxError={
                                                                    handleMaxError
                                                                }
                                                                type={
                                                                    'unVerified'
                                                                }
                                                                changes={
                                                                    changes
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                )}
                                        </ATMGrid.Row>
                                    </>
                                )}
                            </>
                        )}
                    </ATMSegment>)}
                    {isBlockContent && (<div className={style.dimmed}>
                        <p className={style.dimmedText}>Recording new spend or updating existing spend by Prime Users <br />for this month has been temporarily blocked by your Admin</p>
                    </div>)}
                </>
            </ATMContainer>
        </>
    );
};

export default UpdateSpendView;
