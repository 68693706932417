import React, { useState, useEffect } from 'react';
import {
    ORGDataTable,
    ATMButton,
    ATMIcon,
    ATMContainer,
    ATMPopover,
    ATMSegment,
    ATMGrid,
    ATMSelect,
    ATMInput,
    ATMLabel,
    ATMResponsive,
    ATMForm
} from 'shared-it-appmod-ui';
import moment from 'moment';
import Lang from 'src/libraries/language';
import style from './admin-subcontractor.module.scss';
import {
    stateOption,
    alphaNumericStartsWith,
} from '../../../constants/common.constant';

const AdminSubcontractorView: any = ({
    data,
    fetchData,
    loading,
    totalRecord,
    handleViewSubContractor,
    verificationStatus,
}) => {
    console.log(data)
    const tableData = data['data'] ? data['data'] : [];
    const colorCode = (
        <ATMGrid columns={2}>
            <ATMGrid.Row className={style.colorRowTop}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightyellow"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_NEXT_60_DAYS}
                </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row className={style.colorRow}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightorange"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_1_180_DAYS_PAST_DUE}
                </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row className={style.colorRow}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightred"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_LIST_MORE_THAN_180_DAYS_PAST_DUE}
                </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row className={style.colorRow}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightgrey"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_LIST_NOT_FOUND_IN_SCH}
                </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row className={style.colorRow}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightteal"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFIED}
                </ATMGrid.Column>
            </ATMGrid.Row>
        </ATMGrid>
    );
    const columns = [
        {
            title: Lang.LBL_SUBCONTRACTOR,
            index: 'name',

            render: (_, value) => (
                <a
                    style={{
                        cursor: 'pointer',
                        fontSize: '14px',
                        color: '#009BDA',
                        fontWeight: 'bold',
                    }}
                    onClick={() =>
                        handleViewSubContractor(value?.subcontractor_id)
                    }
                >
                    {value.name}
                </a>
            ),
            headerProps: {
                className: style.subcontractor,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTORS_CITY,
            index: 'city',
            render: (_, value) => (
                <span className={style.rowData}>{value.city}</span>
            ),
            headerProps: {
                className: style.city,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_STATE,
            index: 'state',
            render: (_, value) => (
                <span className={style.rowData}>{value.state}</span>
            ),
            headerProps: {
                className: style.state,
            },
        },
        {
            title: (
                <span>
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFICATION}
                    <br /> {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_EXP_STATUS}
                    <ATMPopover
                        trigger={
                            <i
                                className={`info circle icon`}
                                style={{
                                    color: '#848080',
                                    position: 'absolute',
                                    marginTop: '1px',
                                    paddingLeft: '2px',
                                }}
                            ></i>
                        }
                        content={colorCode}
                        size="small"
                        position="bottom center"
                        style={{ marginLeft: '0px', marginTop: '10px' }}
                    />
                </span>
            ),
            index: 'VerificationStatus',
            sortable: false,

            render: (_, value) => (
                <span
                    className={style.rowData}
                    style={{
                        fontSize: '14px',
                        color: '#009BDA',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                    }}
                >
                    <ATMLabel customcolor={value.VerificationStatus.color}>
                        {value.VerificationStatus.label}
                    </ATMLabel>
                </span>
            ),
            headerProps: {
                className: style.verificationcell,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VIEW,
            index: 'actions',
            sortable: false,
            render: (_, value) => {
                return (
                    <span className={style.rowData}>
                        {
                            <a
                                style={{ cursor: 'pointer', padding: '0' }}
                                className="ui icon button"
                            >
                                <i
                                    className="eye icon"
                                    style={{
                                        background: 'transparent',
                                        color: 'grey',
                                    }}
                                    onClick={() =>
                                        handleViewSubContractor(
                                            value?.subcontractor_id
                                        )
                                    }
                                ></i>
                            </a>
                        }
                    </span>
                );
            },
            headerProps: {
                className: style.action,
            },
        },
    ];
    return (
        <>
            <ATMContainer fluid className={style.wrapper}>
                <div // visible onlt to Prime user
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '10px',
                    }}
                >
                    <div className={style.pageTitle}>
                        {Lang.TTL_SUB_CONTRACTOR_MANAGE}
                    </div>
                </div>

                <ATMSegment>
                    <ATMSegment>
                        <ORGDataTable
                            celled={false}
                            columns={columns}
                            data={data?.data ?? []}
                            loading={loading}
                            noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                            // filteredBy={false}
                            sortable
                            history
                            counter
                            total={totalRecord}
                            onChange={fetchData}
                            rowsPerPageOptions={[10, 20, 25]}
                            filters={true}
                            filteredBy={(filterList) =>
                                (filterList || []).map(
                                    (filter) =>
                                        Lang.SUBCONTRACTOR_FILTER_LABEL[
                                            filter.name
                                        ] ?? filter.name
                                )
                            }
                        >
                            {/* {() => ({
                                filters: ({ values, setValue, setError }) => (
                                    <ATMGrid
                                        columns={4}
                                        className={style.filters}
                                        doubling
                                        stackable
                                    >
                                        <ATMGrid.Column>
                                            <label>Name Begins With</label>
                                            <ATMSelect
                                                fluid
                                                placeholder={
                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                }
                                                name="name_begin_with"
                                                value={values.name_begin_with}
                                                options={alphaNumericStartsWith.map(
                                                    (type) => ({
                                                        key: type.key,
                                                        value: type.value,
                                                        text: type.text,
                                                    })
                                                )}
                                                onChange={(_, val) => {
                                                    setValue(
                                                        'name_begin_with',
                                                        val.value
                                                    );
                                                }}
                                            />
                                        </ATMGrid.Column>

                                        <ATMGrid.Column>
                                            <label>
                                                {
                                                    Lang.LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_NAME_CONTAINS
                                                }
                                            </label>

                                            <ATMInput
                                                fluid
                                                placeholder={
                                                    Lang.LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_NAME_CONTAINS
                                                }
                                                name="name"
                                                value={values.name}
                                                onChange={(_, val) => {
                                                    setValue('name', val.value);
                                                }}
                                            />
                                        </ATMGrid.Column>
                                        
                                        <ATMGrid.Column>
                                            <label
                                                style={{
                                                    display: 'table-cell',
                                                }}
                                            >
                                                {
                                                    Lang.LBL_CONTACT_PRIMES_FILTER_LOCATED_IN_THE_STATE
                                                }
                                            </label>
                                            <ATMSelect
                                                fluid
                                                placeholder={
                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                }
                                                options={stateOption}
                                                name="state"
                                                onChange={(_, val) => {
                                                    setValue(
                                                        'state',
                                                        val.value
                                                    );
                                                }}
                                                value={values.state}
                                            />
                                        </ATMGrid.Column>
                                        <ATMGrid.Column>
                                            <label>
                                                {
                                                    Lang.LBL_SUBCONTRACTOR_VERIFICATION_STATUS
                                                }
                                            </label>
                                            <ATMSelect
                                                fluid
                                                placeholder={
                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                }
                                                options={verificationStatus.map(
                                                    (item) => ({
                                                        key: item.value,
                                                        value: item.value,
                                                        text: item.name,
                                                    })
                                                )}
                                                name="verification_expiration_status"
                                                onChange={(_, val) => {
                                                    setValue(
                                                        'verification_expiration_status',
                                                        val.value
                                                    );
                                                }}
                                                value={
                                                    values.verification_expiration_status
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid>
                                ),
                            })} */}


                            

                            {() => ({ 
                                filters: {
                                    name_begin_with: ({ value, setValue }) => (
                                        <>
                                            <ATMResponsive greaterThan="mobile">
                                            {' '}
                                            <ATMForm.Field>
                                                <span className={style.filterSection}>Name Begins With</span>
                                                <ATMSelect
                                                    fluid
                                                    placeholder={
                                                        Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                    }
                                                    name="name_begin_with"
                                                    value={value}
                                                    options={alphaNumericStartsWith.map(
                                                        (type) => ({
                                                            key: type.key,
                                                            value: type.value,
                                                            text: type.text,
                                                        })
                                                    )}
                                                    onChange={(_, val) =>
                                                        setValue(val.value)
                                                    }
                                                />
                                            </ATMForm.Field>
                                            </ATMResponsive>
                                        </>
                                    ),
                                    name: ({ value, setValue }) => (
                                    <>
                                        <ATMResponsive greaterThan="mobile">
                                        {' '}
                                        <ATMForm.Field>
                                            <span className={style.filterSection}>{ Lang.LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_NAME_CONTAINS}</span>
                                            <ATMInput
                                                fluid
                                                placeholder={
                                                    Lang.LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_NAME_CONTAINS
                                                }
                                                name="name"
                                                value={value}
                                                onChange={(_, val) =>
                                                    setValue(val.value)
                                                }
                                            />
                                        </ATMForm.Field>
                                        </ATMResponsive>
                                    </>
                                    ),
                                    state: ({ value, setValue }) => (
                                    <>
                                        <ATMResponsive greaterThan="mobile">
                                        {' '}
                                        <ATMForm.Field>
                                            <span className={style.filterSection}>{ Lang.LBL_CONTACT_PRIMES_FILTER_LOCATED_IN_THE_STATE}</span>
                                            <ATMSelect
                                                fluid
                                                placeholder={
                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                }
                                                options={stateOption}
                                                name="state"
                                                onChange={(_, val) =>
                                                    setValue(val.value)
                                                }
                                                value={value}
                                            />
                                        </ATMForm.Field>
                                        </ATMResponsive>
                                    </>
                                    ),
                                    verification_expiration_status: ({ value, setValue }) => (
                                    <>
                                        <ATMResponsive greaterThan="mobile">
                                        {' '}
                                        <ATMForm.Field>
                                            <span className={style.filterSection}>{ Lang.LBL_SUBCONTRACTOR_VERIFICATION_STATUS}</span>
                                            <ATMSelect
                                                fluid
                                                placeholder={
                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                }
                                                options={verificationStatus.map(
                                                    (item) => ({
                                                        key: item.value,
                                                        value: item.value,
                                                        text: item.name,
                                                    })
                                                )}
                                                name="verification_expiration_status"
                                                onChange={(_, val) =>
                                                    setValue(val.value)
                                                }
                                                value={
                                                    value
                                                }
                                            />
                                        </ATMForm.Field>
                                        </ATMResponsive>
                                    </>
                                    )
                                },
                            })}
                        </ORGDataTable>
                    </ATMSegment>
                </ATMSegment>
            </ATMContainer>
        </>
    );
};
export default AdminSubcontractorView;
