import React from 'react';
import { Container } from 'semantic-ui-react';
import style from './prime-dashboard.module.scss';
import Announcemnt from '../announcement/announcement.component';

import DashboardSummary from '../dashboard-summary/dashboard-summary.component';
import ContractExpirationStatus from '../contract-expiration-status/contract-expiration-status.component';
import DashboardHead from '../dashboard-head/dashboard-head.component';
interface ITilesProps {
    value: string;
    title: string;
}

const Tile: React.FC<ITilesProps> = ({ value, title }) => {
    return (
        <div
            style={{
                // display: 'flex',
                // flexDirection: 'column',
                justifyContent: 'flex-end',
                // alignItems: 'center',
                width: 150,
            }}
            // className={[dFlex, fCol, a, center]}
        >
            <div style={{ fontSize: 32, marginBottom: 10 }}>{value}</div>
            <div style={{ fontSize: 13 }}>{title}</div>
        </div>
    );
};

const PrimeDashboardView: React.FC = () => {
    return (
        <Container fluid className={style.wrapper}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'stretch',
                        height: 110,
                        marginTop: 16,
                    }}
                >
                    <div
                        style={{ flexGrow: 1, margin: 0, marginRight: 5 }}
                        className="ui card"
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                            }}
                        >
                           <DashboardHead />
                        </div>
                    </div>
                    <div
                        style={{
                            flexGrow: 5,
                            margin: 0,
                            marginLeft: 5,
                            display: 'flex',
                            flexDirection: 'row',
                            // justifyContent: 'space-around',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        className="ui card"
                    >
                        <DashboardSummary />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: 442,
                        marginTop: 16,
                    }}
                >
                    <div
                        style={{
                            width: '50%',
                            padding: 19,
                            margin: 0,
                            marginRight: 10,
                        }}
                        className="ui card"
                    >
                        {/* <div
                            style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: 16,
                            }}
                        >
                            Static Data is being displayed here
                        </div> */}
                        <CardHeader1
                            title="Subcontracting Goals vs Actual Summary"
                            leftIcon="calendar alternate outline"
                            // rightText="May (Previous Month) YTD"
                        />
                        {/* <Table /> */}
                        <div
                            style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: 16,
                                position: 'relative',
                                top: '40%',
                            }}
                        >
                            Coming Soon
                        </div>
                    </div>
                    <div
                        style={{
                            width: '50%',
                            padding: 19,
                            margin: 0,
                            marginLeft: 10,
                        }}
                        className="ui card"
                    >
                        {/* <div
                            style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: 16,
                            }}
                        >
                            Static Data is being displayed here
                        </div> */}
                        {/* <Table /> */}
                        <ContractExpirationStatus />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: 243,
                        marginTop: 16,
                    }}
                >
                    <div
                        style={{
                            width: '50%',
                            padding: 19,
                            margin: 0,
                            marginRight: 10,
                        }}
                        className="ui card"
                    >
                        {/* <div
                            style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: 16,
                            }}
                        >
                            Static Data is being displayed here
                        </div> */}
                        <CardHeader1
                            title="Notification"
                            leftIcon="bell outline"
                            // rightText="03 New Notification"
                        />
                        {/* <Table /> */}
                        <div
                            style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: 16,
                                position: 'relative',
                                top: '40%',
                            }}
                        >
                            Coming Soon
                        </div>
                    </div>
                    <div
                        style={{
                            width: '50%',
                            padding: 19,
                            margin: 0,
                            marginLeft: 10,
                        }}
                        className="ui card"
                    >
                        <Announcemnt />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default PrimeDashboardView;

interface ICardHeaderProps {
    title: string;
    leftIcon?: string;
    rightText?: string;
}

const CardHeader1: React.FC<ICardHeaderProps> = ({
    title,
    leftIcon,
    rightText,
}) => {
    return (
        <Container fluid>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{ fontWeight: 'bold' }}>{title}</div>
                <div style={{ color: '#000000A5' }}>
                    {leftIcon && rightText && (
                        <i className={leftIcon + ' icon'}></i>
                    )}
                    {leftIcon && rightText && rightText}
                </div>
            </div>
        </Container>
    );
};

const Table: React.FC = () => {
    return (
        <table className="ui  table">
            <thead>
                <tr>
                    <th>Subcontractor</th>
                    <th>Jan</th>
                    <th>Feb</th>
                    <th>Mar</th>
                    <th>Apr</th>
                    <th>May</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td data-label="Name">1st John INC</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>$500</td>
                </tr>
                <tr style={{ fontWeight: 'bold', background: '#FFA50016' }}>
                    <td data-label="Name">PO Month Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>$500</td>
                </tr>
            </tbody>
        </table>
    );
};
