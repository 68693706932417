import yup from '../libraries/validator.library';
export const ContactPrimeSchema = yup // for non prime schema shape
    .object({
        vendor_id: yup.number().defined(),
        name: yup.string().defined(),
        address: yup.string().defined(),
        city: yup.string().nullable().defined(),
        state: yup.string().nullable().defined(),
        Fav_Id: yup.number().defined(),
        creation_date: yup.string(),
        active: yup.boolean(),
        sapId: yup.number(),
        isToggleAllow: yup.boolean(),
    })
    .defined();

export const ContactPrimeListPayloadSchema = yup // for non prime user listing
    .object({
        data: yup.array().of(ContactPrimeSchema).defined(),
        total_pages: yup.number().nullable(),
        total_rows: yup.number().nullable(),
    })
    .defined();

export type IContactPrime = yup.InferType<typeof ContactPrimeSchema>;
export type IContactPrimeListPayload = yup.InferType<
    typeof ContactPrimeListPayloadSchema
>;

export const ContactPrimesTestingSchema = yup
    .object({
        vendor_id: yup.number().defined(),
        name: yup.string().defined(),
        address: yup.string().defined(),
        city: yup.string().nullable().defined(),
        state: yup.string().nullable().defined(),
        Fav_Id: yup.number().defined(),
        active: yup.boolean(),
    })
    .defined();
export type IContactPrimesTesting = yup.InferType<
    typeof ContactPrimesTestingSchema
>;

export const ContactPrimesUpdateSchema = yup
    .object({
        vendor_id: yup.number().defined(),
        name: yup.string().defined(),
        address: yup.string().defined(),
        city: yup.string().nullable().defined(),
        state: yup.string().nullable().defined(),
        Fav_Id: yup.number().defined(),
        active: yup.boolean(),
    })
    .defined();
export type IContactPrimesUpdate = yup.InferType<
    typeof ContactPrimesUpdateSchema
>;

export const FavouriteUpdateSchema = yup // add selected subcontractors during addition
    .object({
        vendor_id: yup.number().defined(),
        username: yup.string().defined(),
    })
    .defined();
export type IFavouriteUpdate = yup.InferType<typeof FavouriteUpdateSchema>;

export const AllowPrimeTire3SubcontractorsSchema = yup // add selected subcontractors during addition
    .object({
        message: yup.string().nullable().defined(),
        data: yup
            .array()
            .of(
                yup
                    .object({

                    })
                    .defined()
            )
            .defined(),
        status: yup.boolean(),
    })
    .defined();
export type IAllowPrimeTire3SubcontractorsSchema = yup.InferType<typeof AllowPrimeTire3SubcontractorsSchema>;

export const AllowPrimeTire3SubcontractorsAuditLogSchema = yup // add selected subcontractors during addition
    .object({
        message: yup.string().nullable().defined(),
        data: yup
            .array()
            .of(
                yup
                    .object({
                        primeName: yup.string().nullable().defined(),
                        firstName: yup.string().nullable().defined(),
                        lastName: yup.string().nullable().defined(),
                        isToggleAllow: yup.boolean(),
                        updatedAt: yup.string().nullable().defined(),
                        emailBody: yup.string().nullable(),
                        emailSubject: yup.string().nullable(),
                        emailSendStatus: yup.boolean()
                    })
                    .defined()
            )
            .defined(),
        status: yup.boolean(),
        total_rows: yup.number(),
        total_pages: yup.number()
    })
    .defined();
export type IAllowPrimeTire3SubcontractorsAuditLogSchema = yup.InferType<typeof AllowPrimeTire3SubcontractorsAuditLogSchema>;

export const SpendDataAuditLogSchema = yup
    .object({
        data: yup
            .array()
            .of(
                yup
                    .object({
                        subcontractorName: yup.string().nullable().defined(),
                        contract_number: yup.string().nullable().defined(),
                        prevAmount: yup.number().defined(),
                        newAmount: yup.number().defined(),
                        spendMonth: yup.string().nullable().defined(),
                        spendYear: yup.string().nullable().defined(),
                        updatedAt: yup.string().nullable().defined(),
                        updatedBy: yup.string().nullable().defined(),
                        isTier3Spend: yup.boolean().nullable().defined(),
                    })
                    .defined()
            )
            .defined(),
        message: yup.string().nullable().defined(),
        status: yup.boolean(),
        total_rows: yup.number(),
        total_pages: yup.number(),
    })
    .defined();
export type ISpendDataAuditLogSchema = yup.InferType<
    typeof SpendDataAuditLogSchema
>;
