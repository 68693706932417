import services from 'src/services/dashboard.service';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';
import {
    IFetchDashboardStatusSchema,
} from 'src/models/dashboard.model';
import {
    IReportingyearsGetSchema
 } from 'src/models/spend.model';
export const actionTypes = {
    DASHBOARD_STATUS: 'DASHBOARD_STATUS',    
    REPORTING_YEAR: 'REPORTING_YEAR'
} as const;

export const asyncActions = {
    getDashboardStatus: {
        type: actionTypes.DASHBOARD_STATUS,
        service: services.getDashboardStatus,
        status: {} as never,
    }, 
    reportingyearsGet: {
        type: actionTypes.REPORTING_YEAR,
        service: services.reportingyearsGet,
        status: {} as never,
    } 
};

export type IDashboardAsync = typeof asyncActions;

export type IDashboardReducerAction = IAsyncActions<IDashboardAsync>;

export interface IDashboardState
    extends ICommonState<IDashboardReducerAction> {
    dashboardStatus: Partial<IFetchDashboardStatusSchema>;
    reportingyearsGet?: Partial<IReportingyearsGetSchema>;
}

export const defaultState: IDashboardState = {
    status: {},
    dashboardStatus: {},
    reportingyearsGet: {}
};

const DashboardReducer = (
    state: IDashboardState = defaultState,
    action: ICommonAction<IDashboardReducerAction>
): IDashboardState => {
    switch (action.type) {
        case actionTypes.DASHBOARD_STATUS: {
            return {
                ...state,
                dashboardStatus: action.payload || {},
            };
        }
        case actionTypes.REPORTING_YEAR: {
            return {
                ...state,
                reportingyearsGet: action.payload || {},
            };
        }

        default: {
            return state;
        }
    }
};

export default DashboardReducer;
