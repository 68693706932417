import React, { useEffect, useCallback, useRef, useState } from 'react';
import { ATMCheckbox, ATMIcon, ATMPopover, ATMRadio, ATMSelect } from 'shared-it-appmod-ui';
import { getUserID, isAdmin, isPrime, isContact } from 'src/libraries/users.library';
import { useHistory } from 'react-router-dom';
import { ATMGrid, ATMMenu } from 'shared-it-appmod-ui';
import { border } from 'src/constants';
import style from './dashboard-head.module.scss';
import { useContractorContext } from 'src/contexts/contractor.context';


const DashboardHead: React.FC = () => {
    const isUserAdmin = isAdmin();
    const isUserPrime = isPrime();
    const isUserContract = isContact();
    const { state, actions } = useContractorContext();
    const dataRef = useRef<any>();
    const [primedata, setPrimeData] = useState();
    useEffect(() => {
        fetchData();
    }, [actions]);

    const fetchData = useCallback(async () => {
        if(isUserPrime) {
            const userId = getUserID();
            const params = { vendor_id: userId };
            const response = await actions.listGET(params);
            setPrimeData(response.payload?.data?.company_name);
            console.log(response)
        }
    }, [actions, setPrimeData]);


    const selectOptions = [
        {
            key: 0,
            value: 'ALL',
            text: 'ALL'
        // },
        // {
        //     key: 1,
        //     value: 'Xyz',
        //     text: 'Xyz',
        // },
        // {
        //     key: 2,
        //     value: 'Xyz',
        //     text: 'Xyz',
        }
    ]
    // {isUserAdmin && (
    return (
        <>
           
            {false && (
                <div style={{ display: 'contents' }}>
                    <div style={{ width: '50%' }} className={style.leftContent}>
                        <span className={style.main}>
                            <ATMIcon color="blue" name="building" />
                            <span className={style.head}>Primes</span>
                        </span>
                        <p style={{ color: '#000000A5', float: 'left', marginTop: '5px', marginBottom: 0 }}>
                            <ATMRadio
                                checked={true}
                                value={1}
                                name="template"
                                label={'All'}
                                style={{
                                    marginRight: '5px'
                                }}
                            />
                            <ATMRadio
                                checked={false}
                                value={1}
                                name="template"
                                label={'Favourite'}
                                style={{
                                    marginRight: '5px'
                                }}
                            />
                            <ATMRadio
                                checked={false}
                                value={1}
                                name="template"
                                label={'Invidual Prime'}
                                style={{
                                    marginRight: '5px'
                                }}
                            />
                        </p>
                        <p className={style.leftDropDown}>
                            <span style={{ float: 'left', fontWeight: '600' }}>Select Individual Prime</span>
                            <ATMSelect
                                placeholder="Select Prime"
                                options={
                                    selectOptions
                                }
                                disabled
                                className={style.select}
                                compact
                            />
                        </p>
                    </div>

                    <div style={{ width: '50%' }} className={style.rightContent}>
                        <span className={style.main}>
                            <ATMIcon color="blue" name="industry" />
                            <span className={style.head}>Utility</span>
                        </span>
                        <p className={style.rightDropDown}>
                            <ATMSelect
                                placeholder="Select Utility"
                                options={
                                    selectOptions
                                }
                                defaultValue='ALL'
                                className={style.select}
                                compact
                            />
                        </p>
                        <span className={style.main}>
                            <ATMIcon color="blue" name="calendar alternate" />
                            <span className={style.head}>Subcontracting Reporting Status</span>
                        </span>
                        <p className={style.rightDropDown}>
                            <ATMSelect
                                placeholder="Select Subcontracting Reporting Status"
                                options={
                                    selectOptions
                                }
                                defaultValue='ALL'
                                className={style.select}
                                compact
                            />
                        </p>
                    </div>
                </div>
            )}
            {(isUserAdmin || isUserContract) && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                    }}
                >
                    
                    <div
                        style={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            margin: 10,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: 16,
                                position: 'absolute',
                                width: '100%',
                                left: 'auto',
                                right: 0
                            }}
                        >
                            Coming Soon
                        </div>
                         {/* <div>
                            <i
                                className="icon calendar alternate outline"
                                style={{ width: 15, height: 13 }}
                            />
                            Wednesday 19th May 2021
                        </div> */}
                    </div>
                </div>
            )}
            {isUserPrime && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            flexGrow: 1,
                            display: 'flex',
                            margin: '10px 30px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <i
                            className="icon building outline"
                            style={{
                                color: 'white',
                                fontSize: 'x-large',
                                textAlign: 'inherit',
                                width: 50,
                                height: 50,
                                borderRadius: '50%',
                                background: '#2185D0',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        />
                    </div>
                    <div
                        style={{
                            flexGrow: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            margin: 10,
                            justifyContent: 'center',
                            alignItems: 'start',
                        }}
                    >
                        <div
                            style={{
                                fontWeight: 'bold',
                                fontSize: 16,
                            }}
                        >
                            {primedata}
                        </div>
                         {/* <div>
                            <i
                                className="icon calendar alternate outline"
                                style={{ width: 15, height: 13 }}
                            />
                            Wednesday 19th May 2021
                        </div> */}
                    </div>
                </div>
            )}

        </>

    );
};

export default DashboardHead;
