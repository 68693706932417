import React, { useEffect, useState } from 'react';
import { ATMButton, ATMGrid, ATMHeader, ATMSelect } from 'shared-it-appmod-ui';
import { useAdHocSpendFileContext } from 'src/contexts/ad-hoc-spend-file.context';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { month } from '../../../../constants/common.constant';
import Lang from 'src/libraries/language';

import AdHocSpendDataView from './ad-hoc-spend-data.view';

const AdHocSpendData: React.FC = () => {
    const { state, actions } = useAdHocSpendFileContext();
    const [year, setYear] = useState('');
    const [startMonth, setStartMonth] = useState('');
    const [endMonth, setEndMonth] = useState('');
    const [sp3Loading, setsp3Loading] = useState(false);
    const [showGenerate, setShowGenerate] = useState(false);
    const [loadEvent, setLoadEvent] = useState(false);
    
    
    const monthEndObject = month.filter(month => parseInt(startMonth) <= month.value);
   
    useEffect(() => {
        checkStatus();
    },[]);
    const checkStatus = async () => {
        setLoadEvent(true);
        const response = await actions.schFileStatusGet();
        if(response.payload?.status){
            setsp3Loading(response.payload?.data.isInProgress)
        }
        setLoadEvent(false)
    };
    const fetchReport = async () => {
        setLoadEvent(true);
        const data = await actions.SpendD_Data_Report();
        ToastSuccess(data.payload?.message)
        setLoadEvent(false);
    }
    return (
        <div>
            <AdHocSpendDataView
                fetchReport={fetchReport}
                sp3Loading={sp3Loading}
                loadEvent={loadEvent}
            />
        </div>
    );


}

export default AdHocSpendData;