import { useReducer, useMemo, useCallback, useState } from 'react';
import { TYPE_FETCHING, TYPE_FETCHED, TYPE_ERROR } from 'src/constants';
import thunkFactory, { IAsyncActionReturn } from 'src/libraries/thunk.library';

import LoginReducer, { ILoginState, ILoginAsync } from 'src/ducks/login.duck';
import ForgotPasswordReducer, {
    IForgotPasswordState,
    IForgotPasswordAsync,
} from 'src/ducks/forgot-password.duck';

import ContactPrimesReducer, {
    IContactPrimesState,
    IContactPrimesAsync,
} from 'src/ducks/contact-primes.duck';

import SummaryReducer, {
    ISummaryState,
    ISummaryAsync,
} from 'src/ducks/summary.duck';

import ContractorReducer, {
    IContractorState,
    IContractorAsync,
} from 'src/ducks/contractor.duck';

import ContactUserReducer, {
    IContactUserState,
    IContactUserAsync,
} from 'src/ducks/contactuser.duck';

import SubcontractorReducer, {
    ISubcontractorState,
    ISubcontractorAsync,
} from 'src/ducks/subcontractor.duck';
import SpendReducer, { ISpendState, ISpendAsync } from 'src/ducks/spend.duck';
import ContractorPageReducer, {
    IContractorpageState,
    IContractorpageAsync,
} from 'src/ducks/contractorpage.duck';
import ReportReducer, {
    IReportState,
    IReportAsync,
} from 'src/ducks/report.duck';
import ManageSubcontractorReducer, {
    IUpdateSubcontractorDBState,
    IUpdateSubcontractorDBAsync,
} from 'src/ducks/manage-contractor.duck';



import UserConfigReducer, {
    IUserConfigState,
    IUserConfigAsync,
} from 'src/ducks/user-config.duck';

import AdminConsoleReducer, {
    IAdminConsoleState,
    IAdminConsoleAsync,
} from 'src/ducks/admin-console.duck';

import AnnouncementReducer, {
    IAnnouncementAsync,
    IAnnouncementState,
} from 'src/ducks/announcement.duck';

// import MinorityCategoryReducer, { IMinorityCategoryAsync, IMinorityCategoryState }
//     from 'src/ducks/minority-category.duck';

import DashboardReducer, {
    IDashboardAsync,
    IDashboardState,
} from 'src/ducks/dashboard.duck';

import AdHocSpendFileReducer, {
    IAdHocSpendFileState,
    IAdHocSpendFileAsync,
} from 'src/ducks/ad-hoc-spend-file.duck';

import useReducerLogger from './reducer-logger.hook';
import ResetPasswordReducer, {
    IResetPasswordAsync,
} from 'src/ducks/reset-password.duck';
import MassMailReducer, { IIMassMailAsyncState, IMassMailAsync } from 'src/ducks/mass-mail.duck';
// Overloading TypeScript Functions for useReducerLibrary this means that
// every Reducer created must also create a overloading function here
function useReducerLibrary<S = IAdminConsoleState>(
    reducer: typeof AdminConsoleReducer,
    defaultState: S,
    actions: IAdminConsoleAsync
): {
    state: S;
    actions: IAsyncActionReturn<IAdminConsoleAsync>;
};

// function useReducerLibrary<S = IAdminConsoleState>(
//     reducer: typeof MinorityCategoryReducer,
//     defaultState: S,
//     actions: IMinorityCategoryAsync
// ): {
//     state: S;
//     actions: IAsyncActionReturn<IMinorityCategoryAsync>;
// };

function useReducerLibrary<S = ILoginState>(
    reducer: typeof LoginReducer,
    defaultState: S,
    actions: ILoginAsync
): {
    state: S;
    actions: IAsyncActionReturn<ILoginAsync>;
};

function useReducerLibrary<S = IForgotPasswordState>(
    reducer: typeof ForgotPasswordReducer,
    defaultState: S,
    actions: IForgotPasswordAsync
): {
    state: S;
    actions: IAsyncActionReturn<IForgotPasswordAsync>;
};

function useReducerLibrary<S = IUpdateSubcontractorDBState>(
    reducer: typeof ManageSubcontractorReducer,
    defaultState: S,
    actions: IUpdateSubcontractorDBAsync
): {
    state: S;
    actions: IAsyncActionReturn<IUpdateSubcontractorDBAsync>;
};

function useReducerLibrary<S = IContractorState>(
    reducer: typeof ContractorReducer,
    defaultState: S,
    actions: IContractorAsync
): {
    state: S;
    actions: IAsyncActionReturn<IContractorAsync>;
};

function useReducerLibrary<S = IContactUserState>(
    reducer: typeof ContactUserReducer,
    defaultState: S,
    actions: IContactUserAsync
): {
    state: S;
    actions: IAsyncActionReturn<IContactUserAsync>;
};
function useReducerLibrary<S = IContactPrimesState>(
    reducer: typeof ContactPrimesReducer,
    defaultState: S,
    actions: IContactPrimesAsync
): {
    state: S;
    actions: IAsyncActionReturn<IContactPrimesAsync>;
};

function useReducerLibrary<S = ISummaryState>(
    reducer: typeof SummaryReducer,
    defaultState: S,
    actions: ISummaryAsync
): {
    state: S;
    actions: IAsyncActionReturn<ISummaryAsync>;
};

function useReducerLibrary<S = ISubcontractorState>(
    reducer: typeof SubcontractorReducer,
    defaultState: S,
    actions: ISubcontractorAsync
): {
    state: S;
    actions: IAsyncActionReturn<ISubcontractorAsync>;
};

function useReducerLibrary<S = ISpendState>(
    reducer: typeof SpendReducer,
    defaultState: S,
    actions: ISpendAsync
): {
    state: S;
    actions: IAsyncActionReturn<ISpendAsync>;
};

function useReducerLibrary<S = IContractorpageState>(
    reducer: typeof ContractorPageReducer,
    defaultState: S,
    actions: IContractorpageAsync
): {
    state: S;
    actions: IAsyncActionReturn<IContractorpageAsync>;
};

function useReducerLibrary<S = IReportState>(
    reducer: typeof ReportReducer,
    defaultState: S,
    actions: IReportAsync
): {
    state: S;
    actions: IAsyncActionReturn<IReportAsync>;
};



function useReducerLibrary<S = IAdHocSpendFileState>(
    reducer: typeof AdHocSpendFileReducer,
    defaultState: S,
    actions: IAdHocSpendFileAsync
): {
    state: S;
    actions: IAsyncActionReturn<IAdHocSpendFileAsync>;
};

function useReducerLibrary<S = IUserConfigState>(
    reducer: typeof UserConfigReducer,
    defaultState: S,
    actions: IUserConfigAsync
): {
    state: S;
    actions: IAsyncActionReturn<IUserConfigAsync>;
};

function useReducerLibrary<S = IResetPasswordAsync>(
    reducer: typeof ResetPasswordReducer,
    defaultState: S,
    actions: IResetPasswordAsync
): {
    state: S;
    actions: IAsyncActionReturn<IResetPasswordAsync>;
};

function useReducerLibrary<S = IIMassMailAsyncState>(
    reducer: typeof MassMailReducer,
    defaultState: S,
    actions: IMassMailAsync
): {
    state: S;
    actions: IAsyncActionReturn<IMassMailAsync>;
};

function useReducerLibrary<S = IAnnouncementState>(
    reducer: typeof AnnouncementReducer,
    defaultState: S,
    actions: IAnnouncementAsync
): {
    state: S;
    actions: IAsyncActionReturn<IAnnouncementAsync>;
};

function useReducerLibrary<S = IDashboardState>(
    reducer: typeof DashboardReducer,
    defaultState: S,
    actions: IDashboardAsync
): {
    state: S;
    actions: IAsyncActionReturn<IDashboardAsync>;
};

function useReducerLibrary(reducer, defaultState, asyncActions): any {
    // Memoized actions
    const [actionList] = useState(asyncActions);
    // Prevents reinitializing reducer
    const customReducer = useCallback(
        (state, action) => {
            switch (action.status) {
                case TYPE_FETCHING:
                    return {
                        ...state,
                        status: {
                            ...state.status,
                            [action.type]: {
                                fetching: true,
                                error: null,
                            },
                        },
                    };
                case TYPE_FETCHED:
                    // Updates the main reducer state
                    return {
                        ...reducer(
                            {
                                ...state,
                                status: {
                                    ...state.status,
                                    [action.type]: {
                                        fetching: false,
                                        error: null,
                                    },
                                },
                            },
                            action
                        ),
                    };
                case TYPE_ERROR:
                    return {
                        ...state,
                        status: {
                            ...state.status,
                            [action.type]: {
                                fetching: false,
                                error:
                                    action.payload?.message ?? action.payload,
                            },
                        },
                    };
                default:
                    return {
                        ...state,
                        ...reducer(state, action),
                    };
            }
        },
        [reducer]
    );

    const [state, dispatch] = useReducer(
        useReducerLogger(customReducer),
        defaultState
    );

    // Memoized all the actions returned from the factory
    const actions = useMemo(
        () => thunkFactory(actionList, dispatch),
        [actionList, dispatch]
    );
    return { state, actions };
}

export default useReducerLibrary;
