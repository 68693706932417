import services from 'src/services/contact-primes.service';
import {
    IContactPrimeListPayload,
    ISpendDataAuditLogSchema,
    IAllowPrimeTire3SubcontractorsSchema,
    IAllowPrimeTire3SubcontractorsAuditLogSchema
} from 'src/models/contact-primes.model';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';

export const actionTypes = {
    CONTACT_PRIME_LIST_FETCH: 'CONTACT_PRIME_LIST_FETCH',
    PRIMES_FAVOURITE_UPDATE: 'PRIMES_FAVOURITE_UPDATE',
    PRIME_SPEND_AUDIT_LOG: 'PRIME_SPEND_AUDIT_LOG',
    ALLOW_PRIME_TIER_3_SUBCONTRACTORS: 'ALLOW_PRIME_TIER_3_SUBCONTRACTORS',
    ALLOW_PRIME_TIER_3_SUBCONTRACTORS_AUDIT_LOG: 'ALLOW_PRIME_TIER_3_SUBCONTRACTORS_AUDIT_LOG'
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    
    listGET_Contact_Primes: {
        type: actionTypes.CONTACT_PRIME_LIST_FETCH,
        service: services.listGET_Contact_Primes,
        status: {} as never,
    },
    Primes_Favourite_Update: {
        type: actionTypes.PRIMES_FAVOURITE_UPDATE,
        service: services.Primes_Favourite_Update,
        status: {} as never,
    },
    spendDataAuditLogGet: {
        type: actionTypes.PRIME_SPEND_AUDIT_LOG,
        service: services.spendDataAuditLogGet,
        status: {} as never,
    },
    allowPrimeTire3SubcontractorsPost: {
        type: actionTypes.ALLOW_PRIME_TIER_3_SUBCONTRACTORS,
        service: services.allowPrimeTire3Subcontractors,
        status: {} as never,
    },
    allowPrimeTier3SubcontractorsAuditLogGet: {
        type: actionTypes.ALLOW_PRIME_TIER_3_SUBCONTRACTORS_AUDIT_LOG,
        service: services.allowPrimeTire3SubcontractorsAuditLog,
        status: {} as never,
    },
};
export type IContactPrimesAsync = typeof asyncActions;

export type IContactPrimesReducerAction = IAsyncActions<IContactPrimesAsync>;
export interface IContactPrimesState
    extends ICommonState<IContactPrimesReducerAction> {
    
    list: IContactPrimeListPayload;
    spendDataAuditLogGet?: Partial<ISpendDataAuditLogSchema>;
    allowPrimeTire3SubcontractorsPost?: Partial<IAllowPrimeTire3SubcontractorsSchema>;
    allowPrimeTire3SubcontractorsAuditLogGet?: Partial<IAllowPrimeTire3SubcontractorsAuditLogSchema>;
    
}

export const defaultState: IContactPrimesState = {
    status: {},
    list: { data: [], total_pages: 0, total_rows: 0 },
    spendDataAuditLogGet:{},
    allowPrimeTire3SubcontractorsPost:{},
    allowPrimeTire3SubcontractorsAuditLogGet:{}
   };

const ContactPrimesReducer = (
    state: IContactPrimesState = defaultState,
    action: ICommonAction<IContactPrimesReducerAction>
): IContactPrimesState => {
    switch (action.type) {
        case actionTypes.CONTACT_PRIME_LIST_FETCH: {
            return {
                ...state,
                list: action.payload ?? {
                    data: [],
                    total_pages: 0,
                    total_rows: 0,
                },
            };
        }
        case actionTypes.PRIME_SPEND_AUDIT_LOG: {
            return {
                ...state,
                spendDataAuditLogGet: action.payload || {},
            };
        }
        case actionTypes.ALLOW_PRIME_TIER_3_SUBCONTRACTORS: {
            return {
                ...state,
                allowPrimeTire3SubcontractorsPost: action.payload || {},
            };
        }
        
        case actionTypes.ALLOW_PRIME_TIER_3_SUBCONTRACTORS_AUDIT_LOG: {
            return {
                ...state,
                allowPrimeTire3SubcontractorsAuditLogGet: action.payload || {},
            };
        }
        default: {
            return state;
        }
    }
};

export default ContactPrimesReducer;
