/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
    ContractsPage,
    DashboardContractsStatus,
    InvalidContractAuditLogResponseSchema,
    contractsChangeResponseSchema,
    invalidContractsResponseSchema,
    utilityListSchema,
    verifyContractorSchema
} from 'src/models/contractorpage.model';
import { httpClient } from '../libraries/http.library';

const client = httpClient();

export const services = {
    listGET: async (params) => {
        return client.get('/contracts', params, ContractsPage);
    },
    addPOST: async (data) => {
        return client.post('/contracts', data, contractsChangeResponseSchema);
    },
    verifyPOST: async (data) => {
        return client.post(
            '/verifyContractNumber',
            data,
            verifyContractorSchema
        );
    },
    replacePUT: async (data) => {
        return client.put('/contracts', data, contractsChangeResponseSchema);
    },
    utilityListGET: async () => {
        return client.get('/getUtilities', {}, utilityListSchema);
    },
    invalidContractListGET: async (params) => {
        return client.get('/allInvalidPosNumber', params, invalidContractsResponseSchema);
    },
    invalidContractAddPOST: async (data) => {
        return client.post('/allInvalidPosNumber', data, verifyContractorSchema);
    },
    invalidContractAddPUT: async (data) => {
        return client.put('/allInvalidPosNumber', data, verifyContractorSchema);
    },
    getDashboardContractSummary: async (data) => {
        return client.get('/getDashboardContractSummary', data, DashboardContractsStatus);
    },
    getInvalidContractAuditLog: async (params) => {
        return client.get('/allInvalidPosNumberAuditLog', params, InvalidContractAuditLogResponseSchema);
    }

    
};
export default services;
