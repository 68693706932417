import React, { useEffect, useState, useCallback } from 'react';
import { ATMButton, ATMGrid, ATMHeader, ATMSelect } from 'shared-it-appmod-ui';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { month } from '../../../../constants/common.constant';
import Lang from 'src/libraries/language';
import style from './block-external-users.module.scss';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import BlockExternalUsersAuditLog from './block-external-users-audit-log/block-external-users-audit-log.component';

const BlockExternalUsers: React.FC = () => {
    const { state, actions } = useUpdateSubcontractorDBContext();
    const [year, setYear] = useState('');
    const [startMonth, setStartMonth] = useState('');
    const [endMonth, setEndMonth] = useState('');
    const [btnActive, setBtnActive] = useState(false);
    const [buttonAction, setButtonAction] = useState(false);
    const [openAuditLogs, setOpenAuditLogs] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [loadEvent, setLoadEvent] = useState(true);
    
    
    const monthEndObject = month.filter(month => parseInt(startMonth) <= month.value);
    useEffect(() => {
        fetchReport();
    }, [setBtnActive])
    
    const fetchReport = async () => {
        const data = await actions.blockAndUnblockExternalUserGet();
        setBtnActive(data.payload?.data[0]?.isBlockedExternalUser)
        setLoadEvent(false)
    }
    const handleClick = async () => {
        setIsConfirm(true);
    };

    const handleConfirm = useCallback(async (btnActive) => {
        const params = {
            "doBlockExternalUser":btnActive
        }
        await actions.blockAndUnblockExternalUser(params);
        setIsConfirm(false);
        fetchReport();
    }, [actions]);

    const handleOpenAuditLogs = () => {
        setOpenAuditLogs(true);
    }
    const handleOnDiscardAuditLogs = () => {
        setOpenAuditLogs(false);
    }

    const handleOnClose = useCallback(async () => {
        setIsConfirm(false);
    }, []);
    return (

        <>
            {isConfirm && (
                <Confirm
                    open={isConfirm}
                    size="tiny"
                    content={((btnActive == true) ? 'The Prime Users will now be able to record or update their spends through the portal, until you Disable Spend Reporting again. Are you sure you want to proceed?' : 'The Prime Users will NOT be able to record or update their spends any further through the portal, until you Enable Spend Reporting again. Are you sure you want to proceed?')}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleOnClose}
                    onConfirm={() => handleConfirm(!btnActive)}
                />
            )}
            <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>

                <ATMGrid columns={1}>
                    <ATMGrid.Row >
                        
                        <ATMGrid.Column style={{ textAlign: 'center',paddingTop: '25px',paddingBottom: '25px' }}>
                            {loadEvent && (
                                <ATMButton
                                    size="small"
                                    disabled={true}
                                    primary
                                    loading={loadEvent}
                                >
                                    {((btnActive == true) ? 'Enable Spend Reporting' : 'Disable Spend Reporting')}
                                </ATMButton>
                            )}

                            {!loadEvent && (
                                <ATMButton
                                    size="small"
                                    className={(!btnActive ? style.inActive : '')}
                                    primary
                                    onClick={() => handleClick()}
                                >
                                    {((btnActive == true) ? 'Enable Spend Reporting' : 'Disable Spend Reporting')}
                                </ATMButton>
                            )}
                            <ATMButton
                                className="ui primary button"
                                style={{ padding: '5px 10px',float: 'right',position: 'absolute',right: '-10px',top: '0' }}
                                onClick={() => handleOpenAuditLogs()}
                            >
                                Audit Logs
                            </ATMButton>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>

            </div>
            {openAuditLogs && (
                <BlockExternalUsersAuditLog
                    openAuditLogs={openAuditLogs}
                    handleOnDiscardAuditLogs={handleOnDiscardAuditLogs}
                />
            )}
        </>
    );


}

export default BlockExternalUsers;