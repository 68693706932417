import React, { useState, useCallback, useRef } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useContractorPageContext } from 'src/contexts/contractorpage.context';
import { contractorpageFetchStatus, getTableListParams } from 'src/selectors/contractorpage.selector';
import { actionTypes } from 'src/ducks/contractorpage.duck';
import {
    ToastSuccess,
    ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import ContractsView from './contracts.view';
import { getUserID } from 'src/libraries/users.library';
import {
    border,
    leftAlignment,
    rightAlignment,
    centerAlignment,
    reportHeaderBackground,
    reportCellFont,
    reportHeaderFont,
} from 'src/constants/common.constant';
import XLSX from 'xlsx-js-style';
import { saveData } from '../report/report.component';
import moment from 'moment';


export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const Contracts: React.FC = () => {
    const { state, actions } = useContractorPageContext();

    const user_id = getUserID();
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        vendor_id: user_id,
    });
    const tableParamsRef = useRef({ page: 0, limit: 0 });
    const [poNumber, setPoNumber] = useState('');
    const [maNumber, setMaNumber] = useState('');

    const handleDownload = useCallback(
        async (params: IORGDataTableQueryState) => {
            const { page, limit, sort, order, poNumber, maNumber } = params;
            // console.log(params)
            // const { page, limit, vendor_id } = params;
            tableParamsRef.current = params;
            let data: any = {};
            if (params != undefined && params) {
                data = getTableListParams(params);
            }

            const param = {
                sort: sort || 'contract_number',
                order: order || 'ascending',
                page: page,
                limit: limit,
                vendor_id: user_id,
                sempraCompanyName: data?.sempra_company_name || '',
                contractNumber: data?.contract_number || '',
                export: 1
                // poNumber: poNumber || '',
                // maNumber: maNumber || '',
            };
            const result = await actions.listGET(param);
            console.log(result.payload)

            const excelData: any = [];
            result?.payload?.data?.map((contract) => {
                excelData.push({
                    'Utility': contract?.sempra_company_name,
                    'Contract': contract?.contract_number,
                    'Project Description': contract?.description,
                    'Date Added': contract?.dateCreated == null ? '-' : (new Date(contract?.dateCreated).toISOString().split('T')[0] != '1900-01-01' ? moment(contract?.dateCreated).zone(0).format('M/D/YYYY') : '-'),
                    'Expiration Date': contract?.expiryDate == null ? '-' : (new Date(contract?.expiryDate).toISOString().split('T')[0] != '1900-01-01' ? moment(contract?.expiryDate).zone(0).format('M/D/YYYY') : '-'),
                    'PO Creation Date': contract?.poCreationDate == null ? '-' : (new Date(contract?.poCreationDate).toISOString().split('T')[0] != '1900-01-01' ? moment(contract?.poCreationDate).zone(0).format('M/D/YYYY') : '-'),
                    'Total Spend Amount Per PO': formatter.format(contract?.totalSpendAmt),
                    'Contract Valid for Spend Entry': contract?.spendAllowedAdmin ? 'Yes' : (contract?.spendAllowed ? 'Yes' : 'No'),
                    'Validity Explanation': contract?.validityExplanation,
                    'Viewon Update Spend Screen?': contract?.isHideForSpend ? 'No' : 'Yes',
                })
            })
            const wscols = [
                { wch: 20 },
                { wch: 25 },
                { wch: 30 },
                { wch: 20 },
                { wch: 30 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 25 },
                { wch: 40 },
            ];
            let report: any = null;
            try {
                report = XLSX.utils.json_to_sheet(excelData);
                //Format Excel
                for (const key in report) {
                    if (key.replace(/[^0-9]/gi, '') === '1') {
                        const alignment = { ...centerAlignment, wrapText: true };
                        report[key].s = {
                            fill: reportHeaderBackground,
                            alignment: alignment,
                            font: reportHeaderFont,
                            border: border,
                        };
                    } else {
                        const alignment = leftAlignment;
                        report[key].s = {
                            font: reportCellFont,
                            border: border,
                            alignment: alignment,
                        };
                    }
                }

            } catch (e) { }
            report['!cols'] = wscols;
            console.log(excelData)

            const workbook = {
                Sheets: {
                    'Contract': report,
                },
                SheetNames: ['Contract'],
            };

            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            const a = document.createElement('a');
            document.body.appendChild(a);
            saveData(excelBuffer, 'contract_details.xlsx');

        },
        [actions]
    );

    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            const { page, limit, sort, order, poNumber, maNumber } = params;
            tableParamsRef.current = params;
            let data: any = {};
            if (params != undefined && params) {
                data = getTableListParams(params);
            }

            const param = {
                sort: sort || 'contract_number',
                order: order || 'ascending',
                page: page,
                limit: limit,
                vendor_id: user_id,
                sempraCompanyName: data?.sempra_company_name || '',
                contractNumber: data?.contract_number || '',
                // poNumber: poNumber || '',
                // maNumber: maNumber || '',
            };
            await actions.listGET(param);
            // if (params1) {
            //     await setParams((prev) => {
            //         prev.page = params1.page;
            //         prev.limit = params1.limit;
            //         if (params1.sort) {
            //             prev['sort'] = params1.sort;
            //         }
            //         if (params1.order) {
            //             prev['order'] = params1.order;
            //         }
            //         return prev;
            //     });
            // }
            // await actions.ultilityListGET();
            // await actions.listGET(params);
        },
        [actions]
    );

    const editChanges = useCallback(
        async (params1) => {
            params1 = {
                ...params1,
                vendor_id: localStorage.getItem('loginCredential'),
            };
            params1.contract_number = params1?.contract_number?.toString();
            const response: any = await actions.replacePUT(params1);
            if (response?.payload?.status) {
                ToastSuccess('Contract has been updated successfully.');
                fetchData(tableParamsRef.current);
            } else {
                ToastError('Error in updating contract.');
            }
        },
        [actions]
    );

    const addChanges = useCallback(
        async (params1) => {
            params1 = {
                ...params1,
                vendor_id: localStorage.getItem('loginCredential'),
            };
            params1.contract_number = params1?.contract_number?.toString();
            const response: any = await actions.addPOST(params1);
            if (response?.payload?.status) {
                ToastSuccess('Contract has been added successfully');
                fetchData(tableParamsRef.current);
            } else {
                ToastError('Error in adding contract');
            }
        },
        [actions]
    );

    const handleSearch = useCallback(async () => {
        const searchFilterParams = {
            ...params,
            poNumber: poNumber,
            maNumber: maNumber,
        };
        fetchData(searchFilterParams);
    }, [poNumber, maNumber]);

    const handleClear = useCallback(async () => {
        setPoNumber('');
        setMaNumber('');
        fetchData(params);
    }, [poNumber, maNumber]);

    const handleFilter = (key, val) => {
        if (key === 'poNumber') {
            setPoNumber(val);
        }
        if (key === 'maNumber') {
            setMaNumber(val);
        }
    };

    
    const handleDownloadCall = useCallback(async () => {
        handleDownload(tableParamsRef.current);
    }, []);

    return (
        <ContractsView
            data={state?.list}
            editChanges={editChanges}
            addChanges={addChanges}
            fetchData={fetchData}
            loading={
                contractorpageFetchStatus(
                    state,
                    actionTypes.CONTRACTOR_LIST_FETCH
                ).fetching
            }
            handleSearch={handleSearch}
            handleFilter={handleFilter}
            handleClear={handleClear}
            poNumber={poNumber}
            maNumber={maNumber}
            handleDownload={handleDownloadCall}
        />
    );
};

export default Contracts;
