import React, { useCallback, useEffect, useState } from 'react';
import  Confirm from 'src/components/atoms/confirm/confirm.component';
import { ATMButton, ATMGrid } from 'shared-it-appmod-ui';
import { useAdHocSpendFileContext } from 'src/contexts/ad-hoc-spend-file.context';
import Lang from 'src/libraries/language';
import BlockSpendFileGenerationAuditLog from './audit-log/block-spend-file-generation-audit-log.component';
import style from './block-spend-file-generation.module.scss';
import { concatinateArrayWithSpace } from '../../utils/ArrayUtils';

const BlockSpendFileGeneration: React.FC = () => {
  const [openAuditLog, setOpenAuidtLog] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const { state, actions } = useAdHocSpendFileContext();
  const [spendAutoGenerationStateDisable, setSpendAutoGenerationStateDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    setLoading(true);
    const data = await actions.spendFileAutoGenerationActivationStatus();
    setSpendAutoGenerationStateDisable(data.payload?.data[0]?.isBlockedSdrBatchJob)
    setLoading(false)
  }
  const handleClickConfirm = async () => {
    setIsConfirm(true);
  };

  const handleConfirm = useCallback(async () => {
    const params = {
      "isBlockedSdrBatchJob": !spendAutoGenerationStateDisable
    }
    await actions.changeSpendFileAutogenerationActivationStatus(params);
    setIsConfirm(false);
    fetchData();
  }, [actions, spendAutoGenerationStateDisable]);

  const handleOnCloseConfirm = useCallback(async () => {
    setIsConfirm(false);
}, []);
  return (
    <div>
      {isConfirm && (
        <Confirm
          open={isConfirm}
          size="tiny"
          content={spendAutoGenerationStateDisable ? Lang.MSG_ENABLE_SPEND_AUTO_GENERATION_CONFIRM : Lang.MSG_DISABLE_SPEND_AUTO_GENERATION_CONFIRM}
          loading={false}
          btn_text={Lang.LBL_CONFIRM_BTN}
          header={Lang.TTL_CONFIRMATION}
          onCancel={handleOnCloseConfirm}
          onConfirm={() => handleConfirm()}
        />
      )}
      {openAuditLog && <BlockSpendFileGenerationAuditLog open={openAuditLog} handleClose={() => setOpenAuidtLog(false)} />}
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <ATMButton
          className="ui primary button"
          onClick={() => setOpenAuidtLog(true)}
        >
          {Lang.LBL_AUDIT_LOG}
        </ATMButton>
      </div>
      <ATMGrid columns={1}>
        <ATMGrid.Row >
          <ATMGrid.Column style={{ textAlign: 'center', paddingTop: '25px', paddingBottom: '25px' }}>
            <ATMButton
              size="small"
              primary
              loading={loading}
              className={!loading && !spendAutoGenerationStateDisable ? style.inActive : ''}
              disabled={loading}
              onClick={() => handleClickConfirm()}
            >
              {concatinateArrayWithSpace([!spendAutoGenerationStateDisable ? Lang.LBL_DISABLE : Lang.LBL_ENABLE, Lang.LBL_AUTO_SPEND_FILE_GENERATION])}
            </ATMButton>
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </div>
  )
}

export default BlockSpendFileGeneration